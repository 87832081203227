import React from 'react';

import {
    ITreeSelectItemProps,
    ITreeSelectProps,
    TextFieldItem,
    TreeSelect,
    TreeSelectItem,
} from 'ui-lib';
import { MAX_DEVICE_IDENTIFIER_LENGTH } from '../../../common-utils';

export const DeviceGroupMultipleSelect = (props: ITreeSelectProps) => {
    return <TreeSelect {...props} multiple={true}/>;
};

export const DeviceGroupMultipleSelectItem = (props: ITreeSelectItemProps) => {
    return <TreeSelectItem {...props} selectProps={{ ...props.selectProps, multiple: true }}/>;
};

export const DeviceGroupSelectItem = (props: ITreeSelectItemProps) => <TreeSelectItem {...props} />;

export interface IDeviceIdentifierItemProps {
    label?: string;
    labelAlign?: 'left' | 'right';
    initialValue?: string;
    extraValidator?: (value: any) => boolean;
    onChange?: (value: any) => void;
}

export const DeviceIdentifierItem = (props: IDeviceIdentifierItemProps) => {
    const deviceNameValidator = (rule, value, callback) =>
        callback(
            (!props.extraValidator ? true : props.extraValidator(value)) && (!value || value.match('^([A-Za-z0-9_]+)$'))
                ? (value.length <= MAX_DEVICE_IDENTIFIER_LENGTH ? undefined : 'Please enter not more than 32 symbols.')
                : 'Please provide a valid Device UID, serial number or eSN.',
        );

    return (
        <TextFieldItem
            code="deviceIdentifierField"
            label={props.label || 'Device'}
            placeholder="UID, serial number or eSN"
            allowClear={true}
            labelAlign={props.labelAlign}
            initialValue={props.initialValue}
            validator={deviceNameValidator}
            onChange={props.onChange}
        />
    );
};