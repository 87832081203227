import * as React from 'react';
import snapshotCss from './snapshots.css';

import {
    BasicCard,
    BasicCollapse,
    IconTextButton,
    Label,
    notification,
    RowLayout,
    Typography,
    VerticalList,
    WarningAlert,
} from 'ui-lib';

import { ISnapshotProps } from '../../../../dto/software-snapshot-dto';
import { useAuthStore, useSnapshotStore, useStores } from '../../../../store';
import { IPackageProps, PACKAGE_TYPE } from '../../../../dto/packages-dto';
import { NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';
import { uniqBy } from 'lodash';
import { useSnapshotContext } from '.';
import { useSpinnerComponent } from '../../components/loading-component';
import { VersionComparison } from 'common-utils/debian-version-utils';
import { SearchableSelectComponent } from 'pages/private/components/searchable-component';
import { isNullOrEmpty } from 'common-utils';

export interface ISnapshotPublishViewProps {
    snapshotId: number;
}

interface IPackageRecordProps {
    label: string;
    version: string;
    type: PACKAGE_TYPE;
    changedType?: 'UPGRADE' | 'DOWNGRADE';
}

interface ISnapshotsComnparisonResult {
    packages: IPackageRecordProps[];
    addedPackages: IPackageRecordProps[];
    removedPackages: IPackageRecordProps[];
    changedPackages: IPackageRecordProps[];
}

export const PreviewPublishSnapshot = (props: ISnapshotPublishViewProps): JSX.Element => {
    const context = useSnapshotContext();

    const { snapshotId } = props;
    const { SpinnerComponent: SpiningComponent, ...spinningStates } = useSpinnerComponent();
    const snapshotStore = useSnapshotStore();
    const { currentUser } = useAuthStore();
    const { notificationsStore, accountStore } = useStores();

    const [warningMessage, setWarningMessage] = React.useState(null);

    const publishSnapshot = React.useMemo<ISnapshotProps>(() => {
        return snapshotStore.getSnapshot(snapshotId);
    }, [snapshotId]);

    const availableSnapshotsToCompare = React.useMemo<ISnapshotProps[]>(() => {
        return snapshotStore.getSnapshotsByAccountUuid(publishSnapshot?.accountUuid);
    }, [publishSnapshot]);

    const [selectedCompareSnapshot, setSelectedCompareSnapshot] = React.useState<number>();

    const assureApiAccount = React.useMemo(() => {
        return accountStore.getAccountByUuid(publishSnapshot.accountUuid);
    }, [publishSnapshot]);

    React.useEffect(() => {
        if (isNullOrEmpty(availableSnapshotsToCompare)) {
            return;
        }
        setSelectedCompareSnapshot(availableSnapshotsToCompare[0]?.id);
    }, [availableSnapshotsToCompare]);

    const snapshotComparedDetails = React.useMemo<ISnapshotsComnparisonResult>(() => {
        setWarningMessage(undefined);
        if (!publishSnapshot?.id) {
            return undefined;
        }
        const currentSnapshotPackages = snapshotStore.getPackagesBySnapshotId(publishSnapshot.id, true);
        const selectedSnapshotPackages = snapshotStore.getPackagesBySnapshotId(selectedCompareSnapshot, true);

        const removedPackages = selectedSnapshotPackages.filter((item) => !currentSnapshotPackages.find((pkg) => item.label === pkg.label));
        const addedPackages = currentSnapshotPackages.filter((item) => !selectedSnapshotPackages.find((pkg) => item.label === pkg.label));

        const allNewPackages = [...currentSnapshotPackages];
        for (const packageInformation of selectedSnapshotPackages) {
            const foundIndex = allNewPackages.findIndex((item) => item.label === packageInformation.label && item.version === packageInformation.version);
            if (foundIndex < 0) {
                continue;
            }
            allNewPackages.splice(foundIndex, 1);
        }

        const changedPackages = uniqBy(
            allNewPackages.filter((pkg) =>
                selectedSnapshotPackages
                    .filter((item) => item.label === pkg.label)
                    .find((item) => item.version !== pkg.version),
            ),
            (item) => item.label,
        );

        return {
            packages: currentSnapshotPackages,
            addedPackages: addedPackages,
            removedPackages: removedPackages,
            changedPackages: changedPackages.map((changedPkg) => {
                const lastestPackage = selectedSnapshotPackages.find((item) => item.label === changedPkg.label);
                const isHigherVersion = VersionComparison.gte(changedPkg.version, lastestPackage.version);
                if (!isHigherVersion) setWarningMessage('Some packages will be downgraded.');
                return { ...changedPkg, changedType: isHigherVersion ? 'UPGRADE' : 'DOWNGRADE' };
            }),
        };
    }, [publishSnapshot, selectedCompareSnapshot]);

    const publishSnapshotHandler = async (): Promise<void> => {
        if (snapshotComparedDetails.packages.length == 0) {
            notification['error']({
                message: 'There are no packages in the release.',
                placement: 'topRight',
            });
            return;
        }

        try {
            spinningStates.onStart();
            await snapshotStore.publishSnapshot(assureApiAccount.id, snapshotId);
            notification['info']({
                message: 'Release has been successfully published',
                placement: 'topRight',
            });
            await notificationsStore.create({
                content: {
                    title: 'Deployments',
                    text: 'Release has been successfully published',
                    messageType: 'Deployment',
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });
            context.toPublishedSnapshotTable();
        } catch (err) {
            notification['error']({
                message: err.message,
                placement: 'topRight',
            });
        } finally {
            spinningStates.onFinish();
        }
    };

    return (
        <SpiningComponent
            child={
                <div key="previewPublishSnapshot" className={snapshotCss.preview}>
                    {warningMessage ? <WarningAlert message={warningMessage} className={snapshotCss.alert}/> : null}
                    <RowLayout
                        childItems={[
                            <BasicCard
                                key="preview-snapshot-card"
                                code="previewSoftwareSnapshotCard"
                                title="Preview Software Release"
                                bordered={true}
                                contents={
                                    <div className={snapshotCss.contents}>
                                        <SearchableSelectComponent
                                            placeholder="Please select a release to compare"
                                            dataSource={availableSnapshotsToCompare?.map(item => {
                                                return {
                                                    value: item.id,
                                                    label: item.label
                                                };
                                            })}
                                            onChange={setSelectedCompareSnapshot}
                                            selectedValue={selectedCompareSnapshot}
                                            value={selectedCompareSnapshot}
                                        />
                                        <PreviewSnapshotContents publishSnapshot={snapshotComparedDetails}/>
                                    </div>
                                }
                                footers={[
                                    <IconTextButton
                                        key="cancelButton"
                                        label="Cancel"
                                        onClick={context.toCreatedSnapshotTable}
                                    />,
                                    <IconTextButton key="publishButton" label="Publish"
                                                    onClick={publishSnapshotHandler}/>,
                                ]}
                                size="small"
                            />,
                            <BasicCard
                                key="preview-snapshot-to-be-published-card"
                                code="snapshotToBePublishedCard"
                                title="Release to be published"
                                bordered={true}
                                contents={<SnapshotContents label={publishSnapshot?.label}
                                                            publishSnapshot={snapshotComparedDetails}/>}
                                footers={
                                    <IconTextButton
                                        key="backToCreateSnapshotButton"
                                        label="Back to Create Release"
                                        onClick={(): void => context.toModifySnapshot(publishSnapshot.id)}
                                    />
                                }
                                size="small"
                            />,
                        ]}
                        childCols={[9, 9]}
                        childOffsets={[0, 2]}
                    />
                </div>
            }
        />
    );
};

interface IPackageListComponentProps {
    packages?: IPackageRecordProps[];
    render?: (item: IPackageRecordProps) => string;
}

const PackageListComponent = (props: IPackageListComponentProps): JSX.Element => {
    const { packages, render } = props;
    return <VerticalList className={snapshotCss['vertical-list']} listItems={packages?.map((item, index) => ({
        key: `${item.label}-${index}`,
        title: '',
        content: render ? render(item) : item.label,
    }))} noData={<div></div>}/>;
};

const PreviewSnapshotContents = (props: { publishSnapshot: ISnapshotsComnparisonResult }): JSX.Element => {
    const { publishSnapshot } = props;
    return (
        <div>
            <Typography.Text>Please review the contents of your software release.</Typography.Text>
            <br/>
            <Typography.Text>
                You have <span
                className={snapshotCss.added}>added {publishSnapshot?.addedPackages?.length || 0}</span>{' '}
                new packages:
            </Typography.Text>
            <br/>
            <PackageListComponent packages={publishSnapshot?.addedPackages}/>
            <Typography.Text>
                You have{' '}
                <span
                    className={snapshotCss.removed}>removed {publishSnapshot?.removedPackages?.length || 0}</span>{' '}
                packages:
            </Typography.Text>
            <br/>
            <PackageListComponent packages={publishSnapshot?.removedPackages}/>
            <Typography.Text>
                You have{' '}
                <span className={snapshotCss.changed}>
                    changed the versions of {publishSnapshot?.changedPackages?.length || 0}
                </span>{' '}
                packages:
            </Typography.Text>
            <br/>
            <PackageListComponent packages={publishSnapshot?.changedPackages}
                                  render={(item): string => `${item.label} - ${item.changedType}`}/>
        </div>
    );
};

const SnapshotContents = (props: { label: string; publishSnapshot: ISnapshotsComnparisonResult }): JSX.Element => {
    const { label, publishSnapshot } = props;

    const filteredPackages = React.useMemo(() => {
        const packagesByType = new Map<string, IPackageRecordProps[]>();
        if (!publishSnapshot?.packages) return packagesByType;

        return publishSnapshot.packages.reduce((map, item) => {
            map.set(item.type, [...(map.get(item.type) || []), item]);
            return map;
        }, packagesByType);
    }, [publishSnapshot]);

    const packagesPanel = (type: PACKAGE_TYPE) => {
        return {
            header: `${type} packages`,
            key: type,
            content: (
                <PackageListComponent
                    packages={filteredPackages?.get(type)}
                    render={(pkg: IPackageProps): string => `${pkg.label} - version: ${pkg.version}`}
                />
            ),
        };
    };
    return (
        <div key="snapshotContents" className={snapshotCss.contents}>
            <strong>
                <Label label={`Release Name: ${label || ''}`}/>
            </strong>
            <div className={snapshotCss.collapse}>
                <BasicCollapse
                    defaultActiveKey={[PACKAGE_TYPE.CLIENT]}
                    expandIconPosition="start"
                    panels={[
                        packagesPanel(PACKAGE_TYPE.CLIENT),
                        packagesPanel(PACKAGE_TYPE.VAR),
                        packagesPanel(PACKAGE_TYPE.ALM),
                        packagesPanel(PACKAGE_TYPE.SCR),
                        packagesPanel(PACKAGE_TYPE.VOS)
                    ]}
                />
            </div>
        </div>
    );
};
