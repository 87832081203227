import { IApiClientOptions, IRequestProps } from 'core';
import { IAddOrRemoveDeviceReponse, IDeviceGroupOptions, IRequestMoveDeviceProps, TCreateDeviceGroupOptions, TUpdateDeviceGroupOptions, IMoveDeviceToGroupResponseOptions } from '../dto/device-group-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';
import { IImportCSVResponseOptions, IDeviceAttributesResponse } from 'dto/device-dto';

const devicesRootPathName = 'devicegroups';

export interface IDeviceGroupDataInput {
    [attributeName: string]: string | number | boolean;
}

export class DeviceGroupsApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('devicegroups', options);
    }

    protected getRootPath(): string {
        return devicesRootPathName;
    }

    public async createDeviceGroup(accountId: number, deviceGroupName: string, params: TCreateDeviceGroupOptions): Promise<IDeviceGroupOptions> {
        return this.send<IDeviceGroupOptions>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, deviceGroupName, params));
    }

    public async updateDeviceGroup(accountId: number, deviceGroupName: string, params: TUpdateDeviceGroupOptions): Promise<boolean> {
        const result = await this.send<{ updated: boolean }>(HTTP_METHOD.PUT, this.getRequestOptionsWithAccountId(accountId, deviceGroupName, params));
        return result.updated;
    }

    public async deleteDeviceGroup(accountId: number, deviceGroupName: string): Promise<boolean> {
        const result = await this.send<{ deleted: boolean }>(HTTP_METHOD.DELETE, this.getRequestOptionsWithAccountId(accountId, deviceGroupName));
        return result.deleted;
    }

    public async addDevicesToGroup(
        accountId: number,
        groupName: string,
        params: { deviceNames: string[] },
    ): Promise<IAddOrRemoveDeviceReponse[]> {
        const options: IRequestProps = this.getRequestOptionsWithAccountId(
            accountId,
            `${groupName}/add-devices`,
            params,
        );
        return this.send<IAddOrRemoveDeviceReponse[]>(HTTP_METHOD.POST, options);
    }

    public async moveDevicesToGroup(accountId: number, groupName: string, sourceDeviceGroupName: string, deviceNames: string[]): Promise<IMoveDeviceToGroupResponseOptions[]> {
        const options: IRequestProps = this.getRequestOptionsWithAccountId(
            accountId,
            `${groupName}/move-devices`,
            { sourceDeviceGroupName, deviceNames },
        );
        const result = await this.send<{ devices: IMoveDeviceToGroupResponseOptions[] }>(HTTP_METHOD.PATCH, options);
        return result.devices;
    }

    public async importDevices(accountId: number, clientDeviceGroupName: string, destDeviceGroupName: string, files: File[]): Promise<IImportCSVResponseOptions> {
        return this.send<IImportCSVResponseOptions>(
            HTTP_METHOD.POST,
            this.getRequestOptionsWithAccountId(accountId, 'import-devices', { destDeviceGroupName, clientDeviceGroupName, files }),
        );
    }

    public async removeDevicesFromGroup(
        accountId: number,
        groupName: string,
        params: { deviceNames: string[] },
    ): Promise<IAddOrRemoveDeviceReponse[]> {
        const options: IRequestProps = this.getRequestOptionsWithAccountId(
            accountId,
            `${groupName}/remove-devices`,
            params,
        );
        return this.send<IAddOrRemoveDeviceReponse[]>(HTTP_METHOD.POST, options);
    }

    async getDeviceGroupDetailsOfAccount(
        accountId: number,
        query?: { hierarchy: 'true' | 'false' | undefined },
    ): Promise<IDeviceGroupOptions> {
        const options: IRequestProps = {
            url: `/account/${accountId}/devicegroup`,
            params: query,
            options: {
                propagateError: true,
            },
        };
        const result = await this.send<{ devicegroup: IDeviceGroupOptions }>(HTTP_METHOD.GET, options);
        return result.devicegroup;
    }

    async getDeviceGroupOfAccounts(
        accountId: number,
        query?: { hierarchy: 'true' | 'false' | undefined },
    ): Promise<IDeviceGroupOptions[]> {
        const options: IRequestProps = {
            url: `/account/${accountId}/devicegroup/list`,
            params: query,
            options: {
                propagateError: true,
            },
        };
        const result = await this.send<{ deviceGroups: IDeviceGroupOptions[] }>(HTTP_METHOD.GET, options);
        return result.deviceGroups;
    }

    public async revokeDevicesFromGroup(
        accountId: number,
        bodyOptions?: Omit<IRequestMoveDeviceProps, 'destDeviceGroupName'>,
    ): Promise<boolean> {
        const { originDeviceGroupName, ...requestParams } = bodyOptions;
        const options: IRequestProps = this.getRequestOptionsWithAccountId(
            accountId,
            `${originDeviceGroupName}/revoke-devices`,
            requestParams,
        );
        return this.send<boolean>(HTTP_METHOD.POST, options);
    }

    public async timezone(accountId: number, deviceGroup: string, timezone: string): Promise<boolean> {
        const result = await this.send<{ executionId: string }>(HTTP_METHOD.POST, this.getRequestOptionsWithAccountId(accountId, 'timezone', { deviceGroup, timezone }));
        return !!result.executionId;
    }

    public getDeviceAttributes(accountId: number, groupName: string): Promise<IDeviceAttributesResponse> {
        return this.send<IDeviceAttributesResponse>(
            HTTP_METHOD.GET,
            this.getRequestOptionsWithAccountId(accountId, `${groupName}/attributes`),
        );
    }
}
