import * as React from 'react';
import cx from 'classnames';
import { Form, Card, Typography } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CardSize } from 'antd/lib/card';
import basicFormCss from './basic-form.css';
import { isNullOrEmpty } from 'common-utils';
import { DangerAlert, WarningAlert } from '../data-display';
import { isString } from 'lodash';
import { toArrayStrings } from 'common-utils/string-utils';

export interface ICardProps {
    title?: React.ReactNode;
    size?: CardSize;
    bordered?: boolean;
    extra?: React.ReactNode;
}
export interface IBasicFormProps {
    cardPros?: ICardProps;
    items: React.ReactNode[];
    className?: string;
    title?: React.ReactNode;
    onSubmit?: any;
    onError?: any;
    validateTrigger?: any;
    form?: any;
    preventEnterSubmit?: boolean;
    onFieldsChange?: any;
    scrollToFirstError?: boolean;
}
export interface IBasicFormWithAlertsProps extends IBasicFormProps {
    errorMessages?: string | string[];
    warningMessages?: string | string[];
}
const preventEnterKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
};

export function BasicForm(props: IBasicFormProps) {
    return (
        <Card
            className={basicFormCss.wrapper}
            bordered={props.cardPros?.bordered}
            size={props.cardPros?.size || 'small'}
            title={props.cardPros?.title}
            extra={props.cardPros?.extra}
        >
            <Form
                className={cx(basicFormCss.basicForm, props.className)}
                layout='horizontal'
                size='middle'
                scrollToFirstError={props.scrollToFirstError}
                onFinish={props.onSubmit}
                onError={props.onError}
                validateTrigger={props.validateTrigger}
                onKeyDown={props.preventEnterSubmit ? preventEnterKeyDown : undefined}
                onFieldsChange={props.onFieldsChange}
                form={props.form}
            >
                {props.items.map((item, index) => {
                    return <React.Fragment key={index}>{item}</React.Fragment>;
                })}
            </Form>
        </Card>
    );
}
export function BasicFormWithAlerts(props: IBasicFormWithAlertsProps): JSX.Element {
    const { errorMessages, warningMessages, ...formProps } = props;
    const [formErrors, formWarnings] = React.useMemo(() => {
        return [toArrayStrings(errorMessages), toArrayStrings(warningMessages)];
    }, [errorMessages, warningMessages]);

    const toList = (messages: string[]): JSX.Element => {
        return (
            <div>
                {messages.map((message, index) => (
                    <Typography.Paragraph key={`message-${index}`}>{message}</Typography.Paragraph>
                ))}
            </div>
        );
    };
    return (
        <BasicForm
            {...formProps}
            items={[
                isNullOrEmpty(formErrors) ? null : (
                    <DangerAlert key='error-messages' message='Errors' description={toList(formErrors)} />
                ),
                isNullOrEmpty(formWarnings) ? null : (
                    <DangerAlert
                        key='warning-messages'
                        message='Warnings'
                        description={toList(formWarnings)}
                    />
                ),
                ...formProps.items,
            ]}
        />
    );
}

export { useForm } from 'antd/lib/form/Form';
