import React from 'react';
import { observer } from 'mobx-react';
import {
    BasicFormWithAlerts,
    FormItem,
    IconTextButton,
    Label,
    SelectFormItem,
    Spinner,
    TextAreaFieldItem,
    TextFieldItem,
    useForm,
} from 'ui-lib';
import { getErrorMessage } from 'common-utils';
import { confirmCancel } from '../admin/utils';
import { ERROR_CODES } from '../../../dto/error-codes';
import { useAccountStore, useAuthStore, useKeyStore } from '../../../store';
import { OrganisationSelectItem } from '../components/organisation-select';
import manageKeyCss from './keys.css';
import { ITreeSelectDataSource} from '../../../store/account-store';
import { pspTypesForClient } from './key-utils';
import { IUploadKeyOptions, KeyEncryptionType, KeyInputType, KeyType, PSP } from 'dto/key-management/key-dto';
import { buildInputRule } from '../components/form-utils';
import { IAccount, AccountType } from 'dto/access-management/account-dto';

const UPLOAD_KEYS_DESCRIPTION = 'Copy and paste the Key Content provided by your Payment Service Provider';

export interface IUploadKeysComponentProps {
    onCancel: () => void;
    onOk: () => void;
}

export const UploadKeysComponent = observer((props: IUploadKeysComponentProps) => {
    const accountStore = useAccountStore();
    const { currentUser } = useAuthStore();
    const keyStore = useKeyStore();
    const [ kmsAccountWithValidPsp, setKmsAccountWithValidPsp] = React.useState<string[]>([]);
    const loadKmsAccountsWithValidPsp = async () => {

        const kmsAccounts = await keyStore.getKmsAccountsWithValidPspData();
        setKmsAccountWithValidPsp(kmsAccounts.accountUuids);
    };
    React.useEffect(() => {
         loadKmsAccountsWithValidPsp();
    }, []);

    const clientAccountDataSource = React.useMemo<ITreeSelectDataSource>(
        () => buildTreeDataSourceforKmsAccountsWithValidPspData(accountStore.accountHierarchy,Object.values(AccountType),[AccountType.CLIENT],kmsAccountWithValidPsp),
        [kmsAccountWithValidPsp],
    );

    function buildTreeDataSourceforKmsAccountsWithValidPspData(
        root: IAccount,
        accountTypes: AccountType[],
        selectableTypes: AccountType[],
        kmsAccounts: string[]
    ): ITreeSelectDataSource {

        if (!root || (accountTypes !== undefined && !accountTypes.includes(root.accountType))) return undefined;
        const types = selectableTypes ? selectableTypes : Object.values(AccountType);
        const value = { value: root.id, label: root.name, disabled: !types.includes(root.accountType) };
        const subAccounts = (kmsAccounts && kmsAccounts.length != 0) ? root.subAccounts.filter(item => item.accountType == 'VAR' || item.accountType == 'DPU' || (item.accountType == 'CLIENT' && kmsAccounts.includes(item.uuid))) : root.subAccounts;
        const child = subAccounts
            ?.map((sub) => buildTreeDataSourceforKmsAccountsWithValidPspData(sub, accountTypes, selectableTypes, kmsAccounts))
            .filter((sub) => !!sub);
        if (child == undefined || child.length == 0) return { treeDataNode: value };
        return { treeDataNode: value, subTreeDataNode: child };

    }
    const [selectedClient, setSelectedClient] = React.useState<number>(currentUser.accountType == 'CLIENT' && !!clientAccountDataSource ? clientAccountDataSource.treeDataNode.value as number : undefined);
    const [form] = useForm();
    const { pspEncryptionTypeMap, updateSelectedPsp, selectedPsp, supportedKeyTypes } = pspTypesForClient(
        accountStore,
        keyStore,
        selectedClient,
    );

    const keyBlockRule = buildInputRule(
        '[A-Z0-9]',
        { min: 16 },
        'Accepted characters are uppercase letters and numbers.',
    );
    React.useEffect(() => {
        form.setFieldsValue({ pspTypes: selectedPsp, keyTypeField: null });
    }, [selectedPsp]);

    const getVarAccountId = (selectedClientId: number) => {
        switch (currentUser.accountType) {
            case AccountType.VAR:
                return currentUser.accountId;
            case AccountType.DPU:
            case AccountType.CLIENT:
                return accountStore.getAccountById(selectedClientId)?.parent?.id;
        }
   
    };

    const [isUploadKeys, setIsUploadKeys] = React.useState(false);

    const selectedKeyUploadType =  React.useMemo(() => {
        switch (selectedPsp) {
            case PSP.LittlePay:
                if(pspEncryptionTypeMap.get(PSP.LittlePay) === KeyEncryptionType.AES128)
                    return KeyInputType.X9_143_KEY_BLOCK;
                return KeyInputType.ENCRYPTED_VALUE;
            default: {
                return KeyInputType.ENCRYPTED_VALUE;
            }
        }
    }, [selectedPsp]);

    const [errorMessages, setErrorMessages] = React.useState<string | string[]>(undefined);

    const onCancel = () => {
        confirmCancel(() => {
            setErrorMessages(null);
            props.onCancel();
        });
    };

    const getUploadKeys = React.useMemo(() => {
        return (values): IUploadKeyOptions[] => {
            if (selectedKeyUploadType !== KeyInputType.X9_143_KEY_BLOCK) {
                return [
                    {
                        type: values.keyTypeField,
                        content: values.keyContentField,
                        inputType: KeyInputType.ENCRYPTED_VALUE,
                    },
                ];
            }
            return [
                {
                    type: KeyType.BDK,
                    content: values.bdkBlockField,
                    inputType: KeyInputType.X9_143_KEY_BLOCK,
                },
                {
                    type: KeyType.LHMAC,
                    content: values.listHMACKeyBlock,
                    inputType: KeyInputType.X9_143_KEY_BLOCK,
                },
            ];
        };
    }, [selectedKeyUploadType]);

    const onSave = async (values): Promise<void> => {
        try {
            setErrorMessages(null);
            setIsUploadKeys(true);
            const result = await keyStore.createKeys({
                psp: selectedPsp,
                varAccountId: getVarAccountId(values.clientField),
                clientAccountId: values.clientField,
                keyName: values.keyNameField,
                uploadKeys: getUploadKeys(values),
            });
            setErrorMessages(result.errorMessages);
            if (result.created) {
                return props.onOk();
            }
        } catch (error) {
            if (error?.message === ERROR_CODES.EXISTS) {
                setErrorMessages(
                    `You cannot save a new ${keyStore.getKeyTypeLabel(
                        values.keyTypeField,
                    )} for this Client. There are already ${selectedPsp} keys for this Client.`,
                );
                return null;
            }
            setErrorMessages(getErrorMessage(error));
        } finally {
            setIsUploadKeys(false);
        }
    };

    const keyBlockFields = React.useMemo(() => {
        if (selectedKeyUploadType !== KeyInputType.X9_143_KEY_BLOCK) {
            return [];
        }
        const bdkBlockField = (
            <TextAreaFieldItem
                labelColSpan={24}
                code='bdkBlockField'
                label='BDK block'
                textAreaProps={{ autoSize: { minRows: 2, maxRows: 5 } }}
                rule={[{ required: true, message: 'Missing the BDK block' }, keyBlockRule]}
            />
        );
        const listHMACKeyBlock = (
            <TextAreaFieldItem
                labelColSpan={24}
                code='listHMACKeyBlock'
                label='List HMAC key block'
                textAreaProps={{ autoSize: { minRows: 2, maxRows: 5 } }}
                rule={[{ required: true, message: 'Missing the List HMAC key block' }, keyBlockRule]}
            />
        );
        return [bdkBlockField, listHMACKeyBlock];
    }, [selectedKeyUploadType]);

    const keyEncryptedFields = React.useMemo(() => {
        if (selectedKeyUploadType !== KeyInputType.ENCRYPTED_VALUE) {
            return [];
        }
        const keyTypeField = (
            <SelectFormItem
                labelColSpan={24}
                code='keyTypeField'
                label='Key Type'
                isRequired={true}
                dataSource={keyStore.uploadKeyTypes.map((keyType) => ({
                    ...keyType,
                    disabled: !supportedKeyTypes.includes(keyType.value),
                }))}
            />
        );

        const keyContentField = (
            <TextAreaFieldItem
                labelColSpan={24}
                code='keyContentField'
                label='Key Content'
                isRequired={true}
                textAreaProps={{ autoSize: { minRows: 2, maxRows: 5 } }}
            />
        );
        return [keyTypeField, keyContentField];
    }, [keyStore.uploadKeyTypes, supportedKeyTypes, selectedKeyUploadType]);

    const descriptionField = (
        <FormItem
            code='description'
            child={<Label className={manageKeyCss.description} label={UPLOAD_KEYS_DESCRIPTION} />}
        />
    );
    const keyNameField = <TextFieldItem labelColSpan={24} code='keyNameField' label='Key Name' isRequired={true} />;
    const clientField = (
        <OrganisationSelectItem
            labelColSpan={24}
            code='clientField'
            label='Client'
            initialValue={selectedClient}
            isRequired={true}
            selectProps={{
                treeDataSource: clientAccountDataSource,
                expandAll: true,
                onChange: setSelectedClient,
                placeholder:'Clients will be shown in the dropdown only after configuring the PSP(s) in PSP key management by ALM Team',
            }}
        />
    );
    const pspField = (
        <SelectFormItem
            labelColSpan={24}
            code='pspTypes'
            label='PSP'
            isRequired={true}
            selectedValue={selectedPsp}
            onChange={updateSelectedPsp}
            dataSource={Array.from(pspEncryptionTypeMap.keys()).map(pspType => ({ value: pspType, label: pspType }))}
        />
    );

    const actionsField = (
        <div className={manageKeyCss.control}>
            <IconTextButton onClick={onCancel} label='Cancel' />
            <IconTextButton type='primary' htmlType='submit' label='Save' />
        </div>
    );

    return (
        <Spinner className={manageKeyCss.spinner} spinning={isUploadKeys}>
            <BasicFormWithAlerts
                cardPros={{ title: 'Upload Keys', bordered: false }}
                form={form}
                className={manageKeyCss.uploadKeys_form}
                errorMessages={errorMessages}
                items={[
                    descriptionField,
                    keyNameField,
                    clientField,
                    pspField,
                    ...[...keyBlockFields, ...keyEncryptedFields],
                    actionsField,
                ]}
                onSubmit={onSave}
            />
        </Spinner>
    );
});
