import { IAccount } from '../access-management/account-dto';
import { IUser } from '../access-management/user-dto';
import { AvailablePsp } from '../../pages/private/components/psp-select';

export enum KeyEncryptionType {
    TDES192 = "TDES-192",
    RSA4096 = "RSA-4096",
    AES128 = "AES-128"
}

export enum KeyType {
    BDK = 'pspMasterKey',
    LHMAC = 'pspListKey',
    KSID = 'ksid',
    KEY_TABLE_KEY = 'keyTableKey',
}

export enum KCVType {
    BDK = 'kcvBDK',
    LHMAC = 'kcvListHMAC',
    KSID = 'ksid',
    KEY_TABLE_KEY = 'kcvKeyTableKey',
}

export enum PSP {
    LittlePay = 'LittlePay',
    Pulse = 'Pulse',
    Unicard = 'Unicard',
    Vault = 'Vault',
}

export enum KeyInputType {
    ENCRYPTED_VALUE = 'encryptedValue',
    X9_143_KEY_BLOCK = 'keyBlock'
}

export interface IKeyTypeSupportMapped {
    [key: string]: {
        name: string;
        distributable?: boolean;
    }[];
}

export interface IClientKey {
    id: number;
    keyName: string;
    keyType: string;
    status?: string;
    psp?: PSP;
    dateUploaded: Date | string;
}

export interface ICustomerKey extends IClientKey {
    user: IUser;
    varAccount: IAccount;
    clientAccount: IAccount;
}

export interface IUploadCustomerKeyOptions {
    varAccountId: number;
    clientAccountId: number;
    psp: string;
    keyName: string;
    uploadKeys: IUploadKeyOptions[];
}
export interface IUploadCustomerKeyResponse {
    created: boolean;
    errorMessages?: string[];
}
export interface IUploadKeyOptions {
    type: KeyType;
    content: string;
    inputType?: KeyInputType;
}

export interface IDistributeKeyRequestOptions {
    varId?: number;
    clientId?: number;
    deviceIdentifier?: string;
    deviceGroupName?: string;
    deviceGroupNames?: string[];
    psps?: AvailablePsp[];
}

export interface IDistributeKeyResponseResult {
    identifier: string;
    status: 'success' | 'warning' | 'fail';
    errors?: string[];
}

export interface IKcvPspKeyResponse {
    ksid?: string;
    kcvBDK?: string;
    kcvListHMAC?: string;
    kcvKeyTableKey?: string;
}
export interface IClientPspEncryptionType {
    pspType: PSP;
    encryptionType: KeyEncryptionType;
}