import { DEVICE_CONNECTION_STATUS, IDeviceAttributesResult, IDeviceInfo, ISearchDeviceInfo } from 'dto/device-dto';
import * as React from 'react';
import { SEARCH_COLUMN } from './devices-table-component';
import { DevicesStore, IAccount } from 'store/devices-store';
import { AccountType } from 'dto/access-management/account-dto';
import { useStores } from 'store';
import { chunk, isUndefined } from 'lodash';
import { getErrorMessage, getDisplayDeviceStatus, isNullOrEmpty, toDeviceInfo } from 'common-utils';

export interface ISearchDeviceInfoRow extends IDeviceInfo {
    accountId: number;
    firmwareVersion?: string;
}

export class SearchDevicesHandler {
    private readonly _deviceStore: DevicesStore;
    private readonly _searchColumns: SEARCH_COLUMN[];

    constructor(deviceStore: DevicesStore, searchColumns?: SEARCH_COLUMN[]) {
        this._deviceStore = deviceStore;
        this._searchColumns = searchColumns;
    }

    public async getAllDevices(account: IAccount, searchValue?: string): Promise<ISearchDeviceInfo[]> {
        if (account.accountType == AccountType.DPU) return [];
        const params = {};
        if (searchValue) params['searchValue'] = searchValue;
        if (this._searchColumns) {
            params['searchFields'] = this._searchColumns.map((item) =>
                item === SEARCH_COLUMN.UID ? 'deviceName' : item.toString(),
            );
        }
        return this._deviceStore.searchDevices(account.id, { ...params });
    }
}

export interface ISearchDevicesHook {
    columns?: SEARCH_COLUMN[];
    allowSearch?: boolean;
    searchValue?: string;
}

export interface ISearchDevicesHookResult {
    dataSource: ISearchDeviceInfoRow[];
    isLoadingDevices: boolean;
    errorMessage: string | null;
    setAllowSearch: (value: boolean) => void;
    setSearchValue: (value: string) => void;
}

export const useSearchDevices = (accountId: number, searchOptions?: ISearchDevicesHook): ISearchDevicesHookResult => {
    const { deviceStore, accountStore, deviceGroupsStore } = useStores();
    const searchDevicesStore = React.useMemo(
        () => new SearchDevicesHandler(deviceStore, searchOptions?.columns),
        [],
    );
    const account = React.useMemo(() => accountStore.findAccount(accountId), [accountId]);
    const [isLoadingDevices, setIsLoadingDevices] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [allowSearch, setAllowSearch] = React.useState(searchOptions?.allowSearch ?? false);
    const [searchValue, setSearchValue] = React.useState<string>(searchOptions?.searchValue ?? '');
    const loadDeviceAttributes = React.useCallback(
        async (inputGroupNames: string[]) => {
            if (!allowSearch) {
                return;
            }
            try {
                const batches = chunk(
                    inputGroupNames.filter((group) => !!group),
                    5,
                );
                for (const batch of batches) {
                    // eslint-disable-next-line no-await-in-loop
                    const deviceAttributes = await Promise.all(
                        batch.map(async (group) => deviceGroupsStore.getDeviceAttributes(accountId, group)),
                    );
                    setDevicesWithAttributes({ type: 'DEVICE_ATTRIBUTES', payload: deviceAttributes.flat() });
                }
            } catch (err) {
                setErrorMessage(`Error while fetching device statuses: ${getErrorMessage(err)}`);
            }
        },
        [allowSearch, accountId],
    );
    const loadDevices = React.useCallback(
        async (searchValue: string) => {
            if (!account) {
                return [];
            }
            try {
                setErrorMessage(null);
                setIsLoadingDevices(true);
                const devices = await searchDevicesStore.getAllDevices(account, searchValue);
                setDevicesWithAttributes({ type: 'DEVICES', payload: devices });
                return devices;
            } catch (err) {
                setDevicesWithAttributes({ type: 'DEVICES', payload: [] });
                setErrorMessage(getErrorMessage(err));
            } finally {
                setIsLoadingDevices(false);
            }
        },
        [account],
    );
    const [devicesWithAttributes, setDevicesWithAttributes] = React.useReducer(
        (previousState, action) => {
            switch (action.type) {
                case 'DEVICES':
                    return {
                        ...previousState,
                        devices: action.payload,
                    };
                case 'DEVICE_ATTRIBUTES':
                    return {
                        ...previousState,
                        deviceAttributes: [...(previousState.deviceAttributes ?? []), ...action.payload],
                    };
                case 'RESET_ALL':
                    return {
                        devices: [],
                        deviceAttributes: [],
                    };
                case 'CLEAR_DEVICES':
                    return {
                        ...previousState,
                        devices: [],
                    };
                case 'CLEAR_DEVICE_ATTRIBUTES':
                    return {
                        ...previousState,
                        deviceAttributes: [],
                    };
                default:
                    return previousState;
            }
        },
        { devices: [], deviceAttributes: [] },
    );

    const dataSource = React.useMemo<ISearchDeviceInfoRow[]>(() => {
        if (!devicesWithAttributes) {
            return undefined;
        }

        const toSearchDeviceInfoRow = (
            device: ISearchDeviceInfo,
            deviceAttributes?: IDeviceAttributesResult,
        ): ISearchDeviceInfoRow => {
            const status = deviceAttributes
                ? getDisplayDeviceStatus({ status: device.status, connectivity: deviceAttributes.connectivity })
                : DEVICE_CONNECTION_STATUS.LOADING;
            return {
                ...toDeviceInfo(device),
                accountId: account?.id,
                firmwareVersion: device.firmwareVersion,
                status,
            };
        };
        const { devices, deviceAttributes } = devicesWithAttributes;
        if (isNullOrEmpty(devices)) return [];
        if (isUndefined(deviceAttributes)) return devices.map((device) => toSearchDeviceInfoRow(device));
        return devices.map((device) =>
            toSearchDeviceInfoRow(
                device,
                deviceAttributes.find((item) => item.deviceName == device.deviceName),
            ),
        );
    }, [devicesWithAttributes]);

    React.useEffect(() => {
        if (!allowSearch || deviceGroupsStore.dataLoading) {
            return;
        }
        setDevicesWithAttributes({ type: 'RESET_ALL' });
        (async function loadData(): Promise<void> {
            if (isNullOrEmpty(searchValue)) {
                await Promise.allSettled([
                    loadDevices(searchValue),
                    (async (): Promise<void> => {
                        const groupNames = deviceGroupsStore
                            .getDevicesGroupDetails(accountId)
                            .map((item) => item.deviceGroupName);
                        await loadDeviceAttributes(groupNames);
                    })(),
                ]);
                return;
            }
            const loadedDevices = await loadDevices(searchValue);
            const groupNamesInResult = loadedDevices.reduce((groups: Set<string>, device: ISearchDeviceInfo) => {
                device.deviceGroupNames?.forEach((item) => groups.add(item));
                return groups;
            }, new Set<string>());
            await loadDeviceAttributes(Array.from(groupNamesInResult));
        })();
    }, [searchValue, allowSearch, accountId, deviceGroupsStore.dataLoading]);

    return {
        dataSource,
        isLoadingDevices,
        errorMessage,
        setAllowSearch,
        setSearchValue,
    };
};
