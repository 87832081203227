import React from 'react';
import { observer } from 'mobx-react';
import {
    AdvanceTable,
    BasicForm,
    IconTextButton,
    Modal,
    notification,
    showInformationDialog,
    Spinner,
    useForm
} from 'ui-lib';
import {
    getErrorMessage,
    getMovingToDeviceGroupMessage,
    MAX_TIMES_TO_RE_FETCH_DATA,
    TIME_BEFORE_RE_FETCH_IN_SECONDS
} from 'common-utils';
import { DeviceGroupMultipleSelectItem, DeviceIdentifierItem } from '../components/device-groups-select';
import { OrganisationSelectItem } from '../components/organisation-select';
import { OrganisationSelectFormItem } from '../components/organisation-component';
import { AccountType } from '../../../dto/access-management/account-dto';
import { useAccountStore, useAuthStore, useKeyStore, useStores } from '../../../store';
import { useLocation } from 'react-router-dom';
import { useDeviceGroupCommonStates } from '../devices/device-groups';
import { useAccountTreeSelectDataSource } from '../admin/accounts';
import { DeviceUuidHyperlink } from '../components/device-uuid-hyperlink-component';
import { DeviceGroupHyperlink } from '../components/device-group-hyperlink-component';
import { TreeSelect as AntdTreeSelect } from 'antd';

import manageKeyCss from './keys.css';
import { isArray } from 'lodash';
import { AvailablePsp, PspSelect } from '../components/psp-select';

const TARGET_REQUIRED = 'The device, client or device group is required';

export interface IKeyDistributionPageProps {
    uid?: string;
    onCancel: () => void | Promise<void>;
}

export const KeyDistributionPage = observer((props: IKeyDistributionPageProps) => {
    const accountStore = useAccountStore();
    const stores = useStores();
    const [form] = useForm();
    const authStore = useAuthStore();
    const keyStore = useKeyStore();
    const state: Partial<IKeyDistributionPageProps> = useLocation().state || {};

    const {
        treeSelectableDataSource: deviceGroupsTree,
        deviceGroupLoading,
        onAccountChanged,
    } = useDeviceGroupCommonStates();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = React.useState<string>(state.uid || '');
    const [selectedPsps, setSelectedPsps] = React.useState<AvailablePsp[]>([]);
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [selectedDeviceGroups, setSelectedDeviceGroups] = React.useState([]);
    const [deviceUidToLink, setDeviceUidToLink] = React.useState('');
    const [deviceGroupToLink, setDeviceGroupToLink] = React.useState('');
    const [reFetchAfterTimeoutCount, setReFetchAfterTimeoutCount] = React.useState(1);

    const clientDataSource = useAccountTreeSelectDataSource(
        {
            viewableTypes: [AccountType.VAR, AccountType.CLIENT],
            selectableTypes: [AccountType.CLIENT],
            defaultAccountHierarchy: accountStore.findAccount(stores.commonStore.selectedOrganisation),
        }, [stores.commonStore.selectedOrganisation],
    );

    const [showRedistributeKeysResult, setShowRedistributeKeysResult] = React.useState(false);
    const [redistributeKeysDataSource, setRedistributeKeysDataSource] = React.useState([]);

    React.useEffect(() => {
        if (!stores.commonStore.selectedOrganisation) return;
        onAccountChanged(stores.commonStore.selectedOrganisation);
    }, [stores.commonStore.selectedOrganisation]);

    const clearProcessedData = () => {
        setDeviceUidToLink('');
        setDeviceGroupToLink('');
    };
    const hasInputFields = (): boolean => !!selectedDevice || !!selectedClient || !!selectedDeviceGroups.length;
    const clientOrDeviceGroupValidator = (_rule, _value, callback) => (
        callback(hasInputFields() ? undefined : TARGET_REQUIRED)
    );

    const onDistribute = async () => {
        if ([selectedDevice, selectedClient, selectedDeviceGroups].filter((item) => isArray(item) ? item.length > 0 : !!item).length > 1) {
            return showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: 'Cannot use the device, client and device groups fields at the same time.',
            });
        }

        try {
            setIsProcessing(true);
            const result = (
                await keyStore.distributeKey({
                    varId:
                        authStore.currentUser.accountType === AccountType.CLIENT
                            ? null
                            : stores.commonStore.selectedOrganisation,
                    deviceIdentifier: selectedDevice,
                    clientId: selectedClient,
                    deviceGroupNames: selectedDeviceGroups,
                    psps: selectedPsps,
                })
            ).result;

            if (selectedDevice) {
                setDeviceUidToLink(result[0].identifier);
            } else if (selectedDeviceGroups && selectedDeviceGroups.length === 1) {
                setDeviceGroupToLink(selectedDeviceGroups[0]);
            }

            if (!result.some((item) => item.status !== 'success')) {
                setIsProcessing(false);
                return showInformationDialog({
                    modalType: 'info',
                    title: 'Information',
                    content: 'Keys were distributed successfully.',
                });
            }

            if (result.length === 1 && result[0].status !== 'success') {
                setIsProcessing(false);
                return showInformationDialog({
                    modalType: 'error',
                    title: 'Could not distribute keys',
                    content: result[0].errors,
                });
            }

            setShowRedistributeKeysResult(true);
            setRedistributeKeysDataSource(result);
            setIsProcessing(false);
        } catch (err) {
            if (err.message.includes('in the process of moving')) {
                if (reFetchAfterTimeoutCount <= MAX_TIMES_TO_RE_FETCH_DATA) {
                    notification.info({
                        message: `${getMovingToDeviceGroupMessage(Number(err.message.split(' ')[0]))} Waiting...`,
                        placement: 'topRight',
                        duration: TIME_BEFORE_RE_FETCH_IN_SECONDS,
                    });
                    setTimeout(() => {
                        setReFetchAfterTimeoutCount(prev => ++prev);
                    }, TIME_BEFORE_RE_FETCH_IN_SECONDS * 1000);
                } else {
                    showInformationDialog({
                        modalType: 'error',
                        title: 'Could not distribute keys',
                        content: `${getMovingToDeviceGroupMessage(Number(err.message.split(' ')[0]))} Please try the operation again later`,
                    });
                    setReFetchAfterTimeoutCount(1);
                    setIsProcessing(false);
                }
            } else {
                showInformationDialog({
                    modalType: 'error',
                    title: 'Could not distribute keys',
                    content: getErrorMessage(err),
                });
                setIsProcessing(false);
            }
        }
    };

    React.useEffect(() => {
        (async (): Promise<void> => {
            if (reFetchAfterTimeoutCount > 1) {
                await onDistribute();
            }
        })();
    }, [reFetchAfterTimeoutCount]);

    const ResultTable = observer(() => {
        return (
            <AdvanceTable
                title={'Redistribute keys result'}
                table={{
                    columns: [
                        {
                            code: 'identifier',
                            title: 'UID',
                            dataIndex: 'identifier',
                        },
                        {
                            code: 'status',
                            title: 'Status',
                            dataIndex: 'status',
                        },
                        {
                            code: 'errors',
                            title: 'Messages',
                            dataIndex: 'errors',
                        },
                    ],
                    dataSource: redistributeKeysDataSource,
                }}
                toolbar={{
                    extraItems: [
                        <IconTextButton
                            key="close"
                            type="primary"
                            label="Close"
                            onClick={() => setShowRedistributeKeysResult(false)}
                        />,
                    ],
                }}
            />
        );
    });

    return (
        <Spinner
            className={manageKeyCss.spinner}
            spinning={deviceGroupLoading || isProcessing}
        >
            <React.Fragment>
                <BasicForm
                    form={form}
                    className={manageKeyCss.distribution_form}
                    items={[
                        <OrganisationSelectFormItem
                            key="organisation"
                            code="organisation"
                            isRequired={true}
                            labelAlign="right"
                            accountType={authStore.currentUser.accountType}
                            selectedValue={stores.commonStore.selectedOrganisation}
                            onChange={(value) => {
                                form.resetFields(['deviceGroups', 'clientField']);
                                form.setFieldsValue({ 'deviceIdentifierField': '' });
                                setSelectedDevice('');
                                setSelectedClient('');
                                setSelectedDeviceGroups([]);
                                clearProcessedData();
                                stores.commonStore.setSelectedOrganisation(value);
                            }}
                        />,
                        <PspSelect
                            key="psp"
                            code="psp"
                            label="PSP"
                            labelAlign="right"
                            values={selectedPsps}
                            onChange={(values: AvailablePsp[]) => {
                                setSelectedPsps(values);
                            }}
                            isRequired
                        />,
                        <DeviceIdentifierItem
                            key="device-identifier-item"
                            labelAlign="right"
                            initialValue={selectedDevice}
                            extraValidator={hasInputFields}
                            onChange={(value) => {
                                clearProcessedData();
                                form.resetFields(['deviceGroups', 'clientField']);
                                setSelectedClient('');
                                setSelectedDeviceGroups([]);
                                setSelectedDevice(value.target.value);
                            }}
                        />,
                        <OrganisationSelectItem
                            key="clientField"
                            labelAlign="right"
                            code="clientField"
                            label="Client"
                            validator={clientOrDeviceGroupValidator}
                            selectProps={{
                                treeDataSource: clientDataSource,
                                expandAll: true,
                                allowClear: true,
                                onChange: (value) => {
                                    clearProcessedData();
                                    form.resetFields(['deviceGroups', 'deviceIdentifierField']);
                                    form.setFieldsValue({ 'deviceIdentifierField': '' });
                                    setSelectedDevice('');
                                    setSelectedDeviceGroups([]);
                                    setSelectedClient( value || '' );
                                },
                            }}
                        />,
                        <DeviceGroupMultipleSelectItem
                            key="deviceGroups"
                            labelAlign="right"
                            code="deviceGroups"
                            label="Device Groups"
                            validator={clientOrDeviceGroupValidator}
                            selectProps={{
                                dataSource: deviceGroupsTree,
                                expandAll: true,
                                allowClear: true,
                                treeCheckable: true,
                                showCheckedStrategy: AntdTreeSelect.SHOW_PARENT,
                                onChange: (values: string[]) => {
                                    clearProcessedData();
                                    form.resetFields(['clientField', 'deviceIdentifierField']);
                                    form.setFieldsValue({ 'deviceIdentifierField': '' });
                                    setSelectedDevice('');
                                    setSelectedClient('');
                                    setSelectedDeviceGroups(values || []);
                                },
                                filterTreeNode: (inputValue, item) =>
                                    item.title.toLowerCase().includes(inputValue.toLowerCase()),
                            }}
                        />,
                        <div className={manageKeyCss.control} key="distribute-action-buttons">
                            <IconTextButton onClick={props.onCancel} label="Cancel"/>
                            &nbsp;
                            <IconTextButton
                                className={manageKeyCss.control}
                                type="primary"
                                htmlType="submit"
                                label="Distribute"
                            />
                        </div>,
                    ]}
                    onSubmit={onDistribute}
                />
                <Modal isVisible={showRedistributeKeysResult} width={1200}>
                    <ResultTable/>
                </Modal>
                {deviceUidToLink && (
                    <DeviceUuidHyperlink
                        uid={deviceUidToLink}
                        type="primary"
                        label="Go to Device Details"
                        accountId={stores.commonStore.selectedOrganisation}
                    />
                )}
                {deviceGroupToLink && (
                    <DeviceGroupHyperlink deviceGroupName={deviceGroupToLink}/>
                )}
            </React.Fragment>
        </Spinner>
    );
});
