import React from 'react';
import { observer } from 'mobx-react';
import { Modal, SearchField, IconTextButton, DangerAlert, showInformationDialog, Typography } from 'ui-lib';
import { isNullOrEmpty } from 'common-utils';
import { useStores } from '../../../store';
import {
    DEFAULT_DEVICE_COLUMN,
    DEFAULT_EMPTY_TEXT_FOR_SEARCH,
    DevicesTableComponent,
    SEARCH_COLUMN,
} from './devices-table-component';
import componentCss from './component.css';
import { ISearchDeviceInfoRow, useSearchDevices } from './use-search-devices';

export interface ISearchDevicesComponentProps {
    accountId: number;
    searchOptions: {
        key?: string;
        label?: string;
        placeholder?: string;
        allowClear?: boolean;
        columns?: SEARCH_COLUMN[];
    };
    table?: {
        width?: number;
        scroll?: {
            x?: number;
            y?: number;
        };
    };
}

export const SearchDevicesComponent = observer((props: ISearchDevicesComponentProps): JSX.Element => {
    const { accountStore, deviceGroupsStore } = useStores();
    const account = accountStore.findAccount(props.accountId);

    const [showDevicesResult, setShowDevicesResult] = React.useState<boolean>(false);

    const { dataSource, isLoadingDevices, errorMessage, setAllowSearch, setSearchValue } = useSearchDevices(props.accountId, {
        columns: props.searchOptions?.columns,
    });

    React.useEffect(() => {
        setAllowSearch(showDevicesResult);
    }, [showDevicesResult]);

    const onSearch = (searchValue: string): unknown => {
        if (!searchValue.match('^([A-Za-z0-9_]+)$') && !isNullOrEmpty(searchValue)) {
            return showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: 'Please provide a valid Device UID, serial number or eSN.',
            });
        }
        if (!account) {
            return showInformationDialog({
                modalType: 'error',
                title: 'Error',
                content: 'Cannot get the information for the selected account',
            });
        }
        setShowDevicesResult(true);
        setSearchValue(searchValue);
    };

    const tableWidth = props.table?.width || 1200;
    const tableScroll = { x: tableWidth - 100 };
    const placeholder = props.searchOptions?.placeholder || 'Search Devices';

    return (
        <div className={componentCss.searchDevices}>
            {props.searchOptions.label && (
                <Typography.Text className={componentCss.label}>{props.searchOptions.label}</Typography.Text>
            )}
            <SearchField
                key={props.searchOptions?.key || 'searchField'}
                allowClear={props.searchOptions?.allowClear}
                placeholder={placeholder}
                onSearch={onSearch}
                disabled={accountStore.dataLoading || deviceGroupsStore.dataLoading}
            />
            <Modal width={tableWidth} isVisible={showDevicesResult}>
                <React.Fragment>
                    {errorMessage ? <DangerAlert message={errorMessage} /> : null}
                    <DevicesTableComponent<ISearchDeviceInfoRow>
                        title={`Search Results on ${account?.name}`}
                        dataSource={dataSource}
                        loadingState={isLoadingDevices}
                        defaultSortColumn={{
                            key: DEFAULT_DEVICE_COLUMN.LAST_UPDATE,
                            sortOrder: 'descend',
                        }}
                        defaultEmptyText={DEFAULT_EMPTY_TEXT_FOR_SEARCH}
                        buildDeviceLinkProps={(record) => ({ accountId: record.accountId })}
                        displayStatus={true}
                        tableScroll={tableScroll}
                        extraItems={[
                            <IconTextButton
                                key='close'
                                type='primary'
                                label='Close'
                                onClick={() => setShowDevicesResult(false)}
                            />,
                        ]}
                    />
                </React.Fragment>
            </Modal>
        </div>
    );
});
