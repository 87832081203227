import { isString } from 'lodash';

export function hasContainsIgnoreCase(value: string, searchString: string, position?: number): boolean {
    return value?.toLowerCase().includes(searchString?.toLowerCase(), position);
}

export function localeCompare(value: string, compareValue: string): number {
    return value?.localeCompare(compareValue);
}

export function toArrayStrings(value: string | string[]): string[] {
    if (!value) {
        return [];
    }
    return isString(value) ? [value] : value;
}