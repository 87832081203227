import React from 'react';
import { KeyStore } from 'store/key/key-store';
import { AccountStore } from 'store/account-store';
import { usePromiseMemo } from 'utils/common-utils';
import { IClientPspEncryptionType, KCVType, KeyEncryptionType, KeyType, PSP } from 'dto/key-management/key-dto';

export interface IPspStates {
    pspEncryptionTypeMap: Map<PSP, KeyEncryptionType>;
    selectedPsp: string;
    supportedKeyTypes: string[];
    kcvTypes: string[];
    updateSelectedPsp: (value: string) => void;
}

const toKCVEnum = (keyTypeValue: string): string => {
    const keyTypeKey = Object.keys(KeyType).find(item => KeyType[item] === keyTypeValue);
    return KCVType[keyTypeKey];
};

export const pspTypesForClient = (accountStore: AccountStore, keyStore: KeyStore, clientId: number): IPspStates => {
    const [selectedPspType, setSelectedPspType] = React.useState<string>();
    const allSupportedKeyTypes = usePromiseMemo({}, () => {
        return keyStore.getSupportedKeyTypes();
    }, []);

    const supportedKeyTypes = React.useMemo(() => {
        if (!selectedPspType) {
            return [];
        }
        return allSupportedKeyTypes[selectedPspType] ?? [];
    }, [selectedPspType, allSupportedKeyTypes]);

    const kcvTypes = React.useMemo(() => {
        return supportedKeyTypes.map(keyType => {
            return toKCVEnum(keyType);
        });
    }, [supportedKeyTypes]);

    const pspEncryptionTypes = usePromiseMemo<IClientPspEncryptionType[]>(
        [],
        async () => {
            setSelectedPspType(undefined);
            const account = accountStore.getAccountById(clientId);
            if (!account) {
                return [];
            }
            return keyStore.getPspEncryptionTypesOfAccount(account.uuid);
        },
        [clientId],
    );
    const pspEncryptionTypeMap = new Map(pspEncryptionTypes.map(value => [value.pspType, value.encryptionType??null]));
    return {
        pspEncryptionTypeMap,
        selectedPsp: selectedPspType,
        supportedKeyTypes,
        kcvTypes,
        updateSelectedPsp: setSelectedPspType,
    };
};
