import React from 'react';
import { toArrayStrings } from 'common-utils/string-utils';

export interface IAlertMessagesProps {
    errorMessages: string[];
    warningMessages: string[];
    infoMessages: string[];
    addErrorMessages: (messages: string | string[]) => void;
    addWarningMessages: (messages: string | string[]) => void;
    addInfoMessages: (messages: string | string[]) => void;
    clearMessages: () => void;
    clearErrorMessages: () => void;
    clearWarningMessages: () => void;
    clearInfoMessages: () => void;
}
interface IAlertMessages {
    errorMessages: string[];
    warningMessages: string[];
    infoMessages: string[];
}
interface IAlertMessagesAction {
    type: 'error' | 'warning' | 'info' | 'clear';
    payload?: string | string[];
}
export const useAlertMessages = (): IAlertMessagesProps => {
    const [messages, dispatch] = React.useReducer<React.Reducer<IAlertMessages, IAlertMessagesAction>>(
        (state: IAlertMessages, action: IAlertMessagesAction): IAlertMessages => {
            switch (action.type) {
                case 'error':
                    return {
                        ...state,
                        errorMessages: [...state.errorMessages, ...toArrayStrings(action.payload)],
                    };
                case 'warning':
                    return {
                        ...state,
                        warningMessages: [...state.warningMessages, ...toArrayStrings(action.payload)],
                    };
                case 'info':
                    return {
                        ...state,
                        infoMessages: [...state.infoMessages, ...toArrayStrings(action.payload)],
                    };
                case 'clear':
                    return {
                        errorMessages: [],
                        warningMessages: [],
                        infoMessages: [],
                    };
                default:
                    return state;
            }
        },
        {
            errorMessages: [],
            warningMessages: [],
            infoMessages: [],
        },
    );

    return {
        ...messages,
        addErrorMessages: (messages: string | string[]): void => dispatch({ type: 'error', payload: messages }),
        addWarningMessages: (messages: string | string[]): void => dispatch({ type: 'warning', payload: messages }),
        addInfoMessages: (messages: string | string[]): void => dispatch({ type: 'info', payload: messages }),
        clearMessages: (): void => dispatch({ type: 'clear' }),
        clearErrorMessages: (): void => dispatch({ type: 'error', payload: [] }),
        clearWarningMessages: (): void => dispatch({ type: 'warning', payload: [] }),
        clearInfoMessages: (): void => dispatch({ type: 'info', payload: [] }),
    };
};
