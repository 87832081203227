/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable react/display-name */
import * as React from 'react';
import { ISelectItemProps, ISelectProps, Select, SelectFormItem } from '../../../ui-lib';

const withSearchableSelect = <T extends object>(SelectComponent: React.FunctionComponent<T>): React.FunctionComponent<T> => {
    const filterOption = (searchText: string, item: { children: string }): boolean =>
        item.children?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ?? false;
    return (props: T) => <SelectComponent showSearch={true} filterOption={filterOption} {...props} />;
};
export const SearchableSelectFormItem = withSearchableSelect<ISelectItemProps>(SelectFormItem);
export const SearchableSelectComponent = withSearchableSelect<ISelectProps>(Select);