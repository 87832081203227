/* eslint-disable react/jsx-key */
/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { observer } from 'mobx-react';
import { uniq } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import {
    BasicForm,
    DateTimePickerFormItem,
    HorizontalStepper,
    IFormItemValidation,
    IStepItem,
    ITreeSelectDataSource,
    notification,
    SelectFormItem,
    showConfirmDialog,
    Skeleton,
    Spinner,
    SwitchSelectFormItem,
    TextFieldItem,
    Typography,
} from 'ui-lib';

import { getErrorMessage, isNullOrEmpty } from 'common-utils';
import { convertDateByTZ, DATE_TIME_FORMAT, formatDate, NO_TIMEZONE } from 'common-utils/date-utils';
import { OrganisationSelectItem } from 'pages/private/components/organisation-select';
import { DeviceGroupMultipleSelectItem } from '../../components/device-groups-select';
import { useAccountStore, useAuthStore, useDeploymentStore, useDeviceGroupsStore, useStores, } from '../../../../store';
import { useDeviceGroupCommonStates } from '../../devices/device-groups';

import { IDisplayDeploymentGroup, IOutOfServiceSettings } from 'dto/deployment/deployment-group-dto';
import { MESSAGE_TYPE, NOTIFICATION_TYPE } from '../../../../dto/notifications-dto';
import { AccountType } from 'dto/access-management/account-dto';

import deploymentCss from './deployments.css';
import { CompareReleasesStep } from './add-steps/compare-releases';
import { AddReleaseStep } from './add-steps/add-release';
import { ISaveDeploymentProps } from 'dto/deployment/deployment-dto';
import { ReviewAndSaveStep } from './add-steps/review-and-save';
import { buildInputRule } from 'pages/private/components/form-utils';
import { SearchableSelectFormItem } from '../../components/searchable-component';

const Text = Typography.Text;

export interface IAddDeploymentPageProps {
    navigateToDeploymentTable: () => void;
}

export type TNewDeploymentProps = Omit<ISaveDeploymentProps, 'groupId'>;

enum DeploymentTargetType {
    DEVICE = 'DEVICE',
    DEVICE_GROUP = 'DEVICE_GROUP',
}

const deploymentLabelRule = buildInputRule(
    '(?=.*[A-Za-z0-9])^([A-Za-z0-9_+ -.()])',
    { min: 4 },
    'Accepted characters are letters, numbers, space and some special characters "+-_.()!#$%&*". At least one alphanumeric character should be present.',
);

const isValidNewDeploymentLabel = (label: string): boolean => {
    if (!label) {
        return false;
    }
    return deploymentLabelRule.pattern.test(label);
};

export const AddDeploymentPage = observer((props: IAddDeploymentPageProps) => {
    const { treeSelectableDataSource, onAccountChanged } = useDeviceGroupCommonStates();

    const deploymentStore = useDeploymentStore();
    const deviceGroupStore = useDeviceGroupsStore();
    const accountStore = useAccountStore();

    const { notificationsStore, deploymentGroupsStore } = useStores();

    const { currentUser, isDPUAdmin } = useAuthStore();
    const [nextDisabled, setNextDisabled] = React.useState(true);

    const [outOfServiceSettings, setOutOfServiceSettings] = React.useState<IOutOfServiceSettings>(null);
    const [selectedDeviceGroups, setSelectedDeviceGroups] = React.useState([]);
    const [outOfServiceValidation, setOutOfServiceValidation] = React.useState<IFormItemValidation>({
        status: 'success',
    });

    const [selectedDeploymentGroup, setSelectedDeploymentGroup] = React.useState<IDisplayDeploymentGroup>(null);
    const [selectedAccount, setSelectedAccount] = React.useState(() => {
        return currentUser.accountType === AccountType.DPU ? null : accountStore.getAccountById(currentUser.accountId);
    });

    const selectAccountSource = React.useMemo<ITreeSelectDataSource>(
        () => accountStore.getSelectableTreeDataSource(Object.values(AccountType), [AccountType.CLIENT, AccountType.VAR]),
        [currentUser],
    );

    const timezones = React.useMemo<string[]>(() => {
        if (isNullOrEmpty(selectedDeviceGroups))
            return null;

        return uniq(selectedDeviceGroups.map((group) => {
            const tz = group?.deviceGroupTimezone;
            return tz ? tz : NO_TIMEZONE;
        }).filter(tz => !!tz));
    }, [selectedDeviceGroups]);
    const deploymentGroups = React.useMemo<IDisplayDeploymentGroup[]>(() => {
        if (!selectedAccount) return [];
        const accountUuids = (selectedAccount.subAccounts?.map((sub) => sub.uuid) || []).concat(selectedAccount.uuid);
        if (selectedAccount.accountType === AccountType.CLIENT) {
            accountUuids.push(selectedAccount.parent.uuid);
        }
        return deploymentGroupsStore.displayDeploymentGroups.reduce((_, deploymentGroup) => {
            if (accountUuids.includes(deploymentGroup.accountUuid)) {
                _.push(deploymentGroup);
            }
            return _;
        }, []);
    }, [selectedAccount, deploymentGroupsStore.displayDeploymentGroups]);

    const [allowDPUOverride, setAllowDPUOverride] = React.useState<boolean>(false);
    const dateByTZ = (date: Date, tz: string): Date => {
        return (!tz || tz === NO_TIMEZONE) ? dayjs(date).toDate() : convertDateByTZ(date, tz);
    };
    const [selectedScheduleDate, setSelectedScheduleDate] = React.useState<Date>(null);

    const [allowDownloadOnly, setAllowDownloadOnly] = React.useState<boolean>(false);
    const [selectedInstallationDate, setSelectedInstallationDate] = React.useState<Date>(null);
    const targetDateError = React.useMemo<string[]>((): string[] => {
        if (isNullOrEmpty(selectedDeviceGroups))
            return ['The Device groups is required.'];

        if (!selectedScheduleDate)
            return ['The Scheduled date is required.'];

        const scheduledDateOver = timezones?.map((tz) => {
            const scheduledDate = dateByTZ(selectedScheduleDate, tz);
            const now = Date.now();
            if (scheduledDate.getTime() < now)
                return `The Scheduled date is over in ${tz}.`;
            return null;
        }).filter(msg => !!msg);

        if (!isNullOrEmpty(scheduledDateOver))
            return scheduledDateOver;

        if (allowDPUOverride)
            return null;
        else if (!outOfServiceSettings?.serviceWindowStart || !outOfServiceSettings?.serviceWindowEnd)
            return ['The "Out of service window" is not set so the "DPU override" must be turned ON.'];

        if (allowDownloadOnly) {
            if (!selectedInstallationDate)
                return ['The Installation date is required.'];
            if (selectedInstallationDate.getTime() < selectedScheduleDate.getTime())
                return ['The Scheduled date can\'t be greater than the Installation date.'];
        }

        const c = allowDownloadOnly ? selectedInstallationDate : selectedScheduleDate;
        const s = new Date(`${formatDate(c, DATE_TIME_FORMAT.DATE_ONLY)} ${outOfServiceSettings.serviceWindowStart}`);
        const e = new Date(`${formatDate(c, DATE_TIME_FORMAT.DATE_ONLY)} ${outOfServiceSettings.serviceWindowEnd}`);

        // c  [s      e]  c ==> invalid
        //    e]   c  [s    ==> invalid
        const cs = c.getTime() - s.getTime();
        const ec = e.getTime() - c.getTime();
        const es = e.getTime() - s.getTime();
        if ((es >= 0 && (cs < 0 || ec < 0)) || (es < 0 && ec < 0 && cs < 0))
            return [`The ${allowDownloadOnly ? 'Installation' : 'Scheduled'} date must be within the Out of Service Window.`];
        return null;
    }, [allowDPUOverride, allowDownloadOnly, selectedScheduleDate, selectedInstallationDate, outOfServiceSettings, timezones]);

    React.useMemo(() => {
        setNextDisabled(isNullOrEmpty(selectedDeviceGroups) || !isNullOrEmpty(targetDateError));
    }, [targetDateError]);

    const [newDeployment, setNewDeployment] = React.useState<TNewDeploymentProps>({
        label: '',
        deploymentTargets: [],
        releaseId: undefined,
        removalPackages: []
    });

    React.useEffect(
        () => setNextDisabled(!selectedDeploymentGroup || isNullOrEmpty(newDeployment.label?.trim()) || outOfServiceValidation.status !== 'success' || !isValidNewDeploymentLabel(newDeployment.label?.trim())),
        [selectedDeploymentGroup, newDeployment],
    );

    const [errorMessage, setErrorMessage] = React.useState(null);

    const [isSaving, setIsSaving] = React.useState(false);

    React.useEffect(() => {
        onAccountChanged(currentUser.accountId);
    }, [currentUser]);

    const onSave = (): void => {
        showConfirmDialog({
            title: 'Schedule Deployment',
            content: 'Are you sure you want to schedule the deployment?',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: onFinish,
        });
    };

    const onFinish = async (): Promise<void> => {
        async function createNotification(options: { title: string; message: string }): Promise<void> {
            notification.success({
                message: options.message,
                placement: 'topRight',
            });

            await notificationsStore.create({
                content: {
                    title: options.title,
                    text: options.message,
                    messageType: MESSAGE_TYPE.REMOTE_COMMAND,
                },
                type: NOTIFICATION_TYPE.PERSONAL,
                receiverUserUuid: currentUser.uuid,
                sender: currentUser.userName,
            });
        }

        try {
            setIsSaving(true);
            const deployment = await deploymentStore.createDeployment(selectedAccount.id, {
                ...newDeployment,
                groupId: selectedDeploymentGroup.value,
            });
            await createNotification({
                title: 'Deployments',
                message: `New deployment task ${deployment.label} has been created`,
            });

            props.navigateToDeploymentTable();
        } catch (error) {
            notification.error({
                message: getErrorMessage(error),
                placement: 'topRight',
            });
            setErrorMessage(getErrorMessage(error));
        } finally {
            setIsSaving(false);
        }
    };

    const deploymentGroupChanged = (group: IDisplayDeploymentGroup): void => {
        if (!selectedAccount) {
            return;
        }
        setSelectedDeploymentGroup(group);

        const selectedDeploymentGroup = deploymentGroupsStore.findDeploymentGroupById(group.value);
        if (!isDPUAdmin &&
            (!selectedDeploymentGroup.settings?.outOfService.serviceWindowStart ||
                !selectedDeploymentGroup.settings?.outOfService.serviceWindowEnd)) {
            setOutOfServiceValidation({
                status: 'error',
                message: '"Out of service window" has not been set for this deployment group.'
            });
            return;
        }
        setOutOfServiceValidation({
            status: 'success'
        });

        setOutOfServiceSettings(selectedDeploymentGroup.settings?.outOfService);
        setSelectedDeviceGroups(selectedDeploymentGroup.targetDeviceGroupNames.map((name) => deviceGroupStore.getDeviceGroupByName(name)));
        setSelectedScheduleDate(null);
    };

    const onDeviceGroupChange = (values: string[]): void => {
        setSelectedDeviceGroups(values.map((name) => deviceGroupStore.getDeviceGroupByName(name)));
    };

    const onScheduledDateChanged = (value: Dayjs): void => {
        const scheduledDate = value.toDate();
        scheduledDate.setSeconds(0);
        setSelectedScheduleDate(scheduledDate);
        if (!selectedInstallationDate) {
            setSelectedInstallationDate(scheduledDate);
        }
    };

    const onInstallationDateChanged = (value: Dayjs): void => {
        const installationDate = value.toDate();
        installationDate.setSeconds(0);
        setSelectedInstallationDate(installationDate);
    };

    const addTarget = (): void => {
        const newDeploymentTargets = selectedDeviceGroups.map((group) => ({
            targetNames: [group.deviceGroupName],
            deviceGroupIds: [group.id],
            targetLabels: [deviceGroupStore.getDeviceGroupByName(group.deviceGroupName)?.deviceGroupLabel],
            scheduleDateTime: dateByTZ(selectedScheduleDate, group.deviceGroupTimezone),
            type: DeploymentTargetType.DEVICE_GROUP,
            dpuOverride: allowDPUOverride,
            downloadOnly: allowDownloadOnly,
            installDateTime: !selectedInstallationDate ? null : dateByTZ(selectedInstallationDate, group.deviceGroupTimezone),
        }));

        setNewDeployment({ ...newDeployment, deploymentTargets: newDeploymentTargets });
    };

    const addReleaseToDeployment = (releaseId: number): void => {
        setNewDeployment({ ...newDeployment, releaseId: releaseId });
        setNextDisabled(false);
    };

    const addRemovalPackages = (removalPackages: string[]): void => {
        setNewDeployment((previousState) => {
            return { ...previousState, removalPackages: removalPackages };
        });
        setNextDisabled(false);
    };

    const DeploymentGroupItem = observer(() => {
        return <SearchableSelectFormItem
            code="deploymentGroupItem"
            labelInValue={true}
            label="Deployment group"
            labelAlign="right"
            placeholder="Select a deployment group"
            dataSource={deploymentGroups}
            selectedValue={selectedDeploymentGroup?.value}
            onChange={(group: any): void => {
                deploymentGroupChanged(deploymentGroupsStore.findDeploymentGroupById(group.value));
            }}
            customizeValidate={outOfServiceValidation}
        />;
    });

    const step1content = (
        <Skeleton
            loading={deploymentGroupsStore.dataLoading}
            children={
                <BasicForm
                    cardPros={{ bordered: false }}
                    items={[
                        currentUser.accountType == AccountType.DPU && (
                            <OrganisationSelectItem
                                code="selectAccount"
                                label="Account"
                                labelAlign="right"
                                selectProps={{
                                    treeDataSource: selectAccountSource,
                                    value: selectedAccount?.id,
                                    placeholder: 'Select Account',
                                    expandAll: true,
                                    onChange: (value): void => {
                                        setSelectedAccount(accountStore.getAccountById(value));
                                        setSelectedDeploymentGroup(null);
                                    },
                                }}
                            />
                        ),
                        <DeploymentGroupItem/>,
                        <TextFieldItem
                            code="newDeploymentName"
                            label="Deployment name"
                            labelAlign="right"
                            rule={deploymentLabelRule}
                            initialValue={newDeployment.label}
                            onChange={(ev): void => setNewDeployment({ ...newDeployment, label: ev.target.value })}
                        />,
                    ]}
                />
            }
        />
    );

    const step4content = (
        <div className={deploymentCss.targetSettings}>
            <section className={deploymentCss.settings}>
                <BasicForm
                    cardPros={{ bordered: false }}
                    items={[
                        <DeviceGroupMultipleSelectItem
                            code="deviceGroupsField"
                            label="Device groups"
                            labelAlign="right"
                            isRequired={true}
                            initialValue={selectedDeviceGroups?.map((group) => group.deviceGroupName)}
                            selectProps={{
                                dataSource: treeSelectableDataSource,
                                expandAll: true,
                                onChange: onDeviceGroupChange,
                            }}
                        />,
                        isDPUAdmin && (
                            <SwitchSelectFormItem
                                code="dpuOverideField"
                                label="DPU override"
                                labelAlign="right"
                                switchSelectProps={{
                                    defaultChecked: allowDPUOverride,
                                    onChange: (value): void => {
                                        setAllowDPUOverride(value);
                                        setAllowDownloadOnly(false);
                                    },
                                }}
                            />
                        ),
                        !allowDPUOverride && (
                            <SwitchSelectFormItem
                                code="downloadOnlyField"
                                label="Download only"
                                labelAlign="right"
                                switchSelectProps={{
                                    defaultChecked: allowDownloadOnly,
                                    onChange: (value): void => {
                                        setAllowDownloadOnly(value);
                                        const installationDate = dayjs().toDate();
                                        installationDate.setSeconds(0);
                                        setSelectedInstallationDate(installationDate);
                                    },
                                }}
                            />
                        ),
                        <DateTimePickerFormItem
                            code="scheduledDateField"
                            label="Scheduled date"
                            labelAlign="right"
                            disabledDateType={'NO_RESTRICTION'}
                            showTime={true}
                            minuteStep={5}
                            showNow={false}
                            allowClear={false}
                            initialValue={!selectedScheduleDate ? null : dayjs(selectedScheduleDate)}
                            dateFormat={DATE_TIME_FORMAT.DATE_AND_SHORT_TIME}
                            onChange={onScheduledDateChanged}
                        />,
                        allowDownloadOnly && (
                            <DateTimePickerFormItem
                                code="installDateField"
                                label="Installation date"
                                labelAlign="right"
                                disabledDateType={'NO_RESTRICTION'}
                                showTime={true}
                                minuteStep={5}
                                showNow={false}
                                allowClear={false}
                                initialValue={!selectedInstallationDate ? null : dayjs(selectedInstallationDate)}
                                dateFormat={DATE_TIME_FORMAT.DATE_AND_SHORT_TIME}
                                onChange={onInstallationDateChanged}
                            />
                        ),
                    ]}
                />
            </section>
            <section className={deploymentCss.description}>
                <ul>
                    <li key="desc_generic">
                        <Text type="warning">
                            <strong>Note:</strong> the Scheduled/Installation date is based on the timezone of the
                            device groups.
                        </Text>
                    </li>
                    {timezones && timezones.length > 0 ? (
                        <li key="desc_tz">
                            Timezone: <Text code>{timezones.join(', ')}</Text>
                        </li>
                    ) : (
                        ''
                    )}
                    {outOfServiceSettings ? (
                        <li key="desc_out_of_service">
                            Out of Service Window:{' '}
                            <Text code>
                                {outOfServiceSettings.serviceWindowStart} ~ {outOfServiceSettings.serviceWindowEnd}
                            </Text>
                        </li>
                    ) : (
                        ''
                    )}
                    {targetDateError?.map((error, i) => {
                        return (
                            <li key={`desc_error_${i}`}>
                                <Text type="danger">{error}</Text>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </div>
    );

    const steps: IStepItem[] = [
        {
            code: '1',
            iconName: 'chart-network',
            label: 'Create deployment',
            description: 'Create a deployment',
            content: step1content,
            disableNextButton: nextDisabled,
        },
        {
            code: '2',
            iconName: 'list-radio',
            label: 'Add Release',
            description: 'Add software and configuration updates',
            content: (
                <AddReleaseStep
                    accountUuid={selectedDeploymentGroup?.accountUuid}
                    selectedReleaseId={newDeployment.releaseId}
                    addReleaseToDeployment={addReleaseToDeployment}
                />
            ),
            beforeBack: () => setNextDisabled(!newDeployment.label),
            disableNextButton: !newDeployment?.releaseId,
        },
        {
            code: '3',
            iconName: 'cloud-arrow-up',
            label: 'COMPARE RELEASES',
            description: 'Compare releases before deployment',
            content: (
                <CompareReleasesStep
                    key={'compare-releases-step'}
                    releaseId={newDeployment.releaseId}
                    selectedDeploymentGroup={selectedDeploymentGroup}
                    addRemovalPackages={addRemovalPackages}
                />
            ),
            disableNextButton: nextDisabled,
            beforeNext: (): void => {
                if (!selectedScheduleDate) {
                    const scheduledDate = dayjs().add(2, 'minutes').toDate();
                    scheduledDate.setSeconds(0);
                    setSelectedScheduleDate(scheduledDate);
                }
                setNextDisabled(!isNullOrEmpty(targetDateError));
            },
            beforeBack: () => setErrorMessage(null),
        },
        {
            code: '4',
            iconName: 'bullseye-arrow',
            label: 'Add deployment targets',
            description: 'Add a schedule and set device groups',
            content: step4content,
            beforeBack: () => setNextDisabled(!newDeployment.releaseId),
            beforeNext: addTarget,
            disableNextButton: nextDisabled,
        },
        {
            code: '5',
            iconName: 'cloud-arrow-up',
            label: 'Review and save',
            description: 'Save the deployment for execution',
            content: <ReviewAndSaveStep
                newDeployment={newDeployment}
                errorMessage={errorMessage}
                allowDPUOverride={allowDPUOverride}
                allowDownloadOnly={allowDownloadOnly}
                selectedDeviceGroups={selectedDeviceGroups}
                selectedInstallationDate={selectedInstallationDate}
                selectedScheduleDate={selectedScheduleDate}
                targetDateError={targetDateError}
            />,
            beforeBack: () => setErrorMessage(null),
        },
    ];

    return (
        <div className={deploymentCss.add}>
            <Spinner
                spinning={isSaving}
                className={deploymentCss.spinner}
                children={
                    <HorizontalStepper
                        items={steps}
                        currentStepCode={'1'}
                        canGoBack={true}
                        onFinish={onSave}
                        finishLabel={'SCHEDULE'}
                        onCancel={props.navigateToDeploymentTable}
                    />
                }
            />
        </div>
    );
});
