import { isNullOrEmpty } from 'common-utils';
import { isString } from 'lodash';
import React from 'react';
import { DangerAlert, IAlertProps, InfoAlert, Typography, WarningAlert } from 'ui-lib';

export interface IAlertMessagesProps {
    errorMessages?: string | string[];
    warningMessages?: string | string[];
    infoMessages?: string | string[];
    closeable?: boolean;
    className?: string;
}
export const MessagesAlert = (props: IAlertMessagesProps): JSX.Element => {
    const { errorMessages, warningMessages, infoMessages, closeable, className } = props;

    const hasMessages = !isNullOrEmpty(errorMessages) || !isNullOrEmpty(warningMessages) || !isNullOrEmpty(infoMessages);
    if (!hasMessages) {
        return <></>;
    }

    const toList = (messages: string[]): JSX.Element => {
        return (
            <div>
                {messages.map((message, index) => (
                    <Typography.Paragraph key={`message-${index}`}>{message}</Typography.Paragraph>
                ))}
            </div>
        );
    };

    const toAlertComponent = (messages: string | string[], Component: (props: IAlertProps) => JSX.Element, title: string): JSX.Element => {
        if (isNullOrEmpty(messages)) {
            return <></>;
        }
        if (isString(messages)) {
            return (
                <Component
                    key={`${Component.name}-messages`}
                    className={className}
                    message={messages}
                    closable={closeable}
                />
            );
        }
        return (
            <Component
                key={`${Component.name}-messages`}
                className={className}
                message={title}
                closable={closeable}
                description={toList(messages)}
            />
        );
    };
    return (
        <div>
            {toAlertComponent(errorMessages, DangerAlert, 'Errors')}
            {toAlertComponent(warningMessages, WarningAlert, 'Warnings')}
            {toAlertComponent(infoMessages, InfoAlert, 'Info')}
        </div>
    );
};
