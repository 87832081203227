import React from 'react';
import { observer } from 'mobx-react';
import packagesCss from './packages.css';
import { PACKAGE_ARCH, PACKAGE_TYPE } from 'dto/packages-dto';
import {
    BasicCard,
    BasicForm,
    FormItem,
    IBasicTableColumn,
    IconTextButton,
    ISelectItemProps,
    ITreeSelectDataSource,
    Label,
    notification,
    RowLayout,
    SelectFormItem,
    showConfirmDialog,
    TextAreaFieldItem,
    TextFieldItem,
    toSelectItemFn,
    TransferWithContentTable,
    Typography,
    useForm,
} from 'ui-lib';

import { useAuthStore, usePackagesStore, useSnapshotStore as useSnapshotStore, useStores, } from '../../../../store';
import { TDisplayPackageProps } from '../../../../store/packages-store';
import { manageSnapshotPermission, useSnapshotContext } from '.';
import { ViewSnapshotPackageComponent } from './snapshot-details';
import { isEqual, toNumber, without } from 'lodash';
import { confirmCancel } from '../../admin/utils';
import { useSpinnerComponent } from '../../components/loading-component';
import { OrganisationSelectItem } from 'pages/private/components/organisation-select';
import { AccountType, IAccount } from 'dto/access-management/account-dto';
import { getErrorMessage, isNullOrEmpty } from 'common-utils';
import { IMetaPackageInReleaseProps, ISnapshotProps, METAPACKAGE_TYPE } from 'dto/software-snapshot-dto';
import { SearchableSelectFormItem } from '../../components/searchable-component';
import { hasContainsIgnoreCase } from 'common-utils/string-utils';
import {
    showDuplicatePackagesModal,
    showMissingPackageDependenciesModal,
    showPackageDetailsModal
} from 'pages/private/components/package-modal-components';
import { IDisplaySnapshotProps } from 'store/snapshot.store';

function confirmDialog(content: string, action: () => void): void {
    showConfirmDialog({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: action,
    });
}

interface ISaveSnapshotProps {
    apiAccountId: number;
    label: string;
    description?: string;
    accountUuid: string;
    basedReleaseId: number;
    almReleaseId: number;
    vosReleaseId: number;
    scrReleaseId: number;
    selectedPackages: number[];
}

interface ISnapshotDetails {
    title?: string;
    name?: string;
    description?: string;
    changeableTargetPackages: number[];
    accountUuid?: string;
    defaultMetapackages: IMetaPackageInReleaseProps;
    viewOnly: boolean;
    handler: (options: ISaveSnapshotProps) => Promise<unknown>;
    basedReleaseId?: number;
}

interface IAddPackageProps extends Omit<ISnapshotDetails, 'handler' | 'viewOnly'> {
    loading: boolean;
    onSaveSnapshot: (options: ISaveSnapshotProps) => Promise<void>;
    onCancel: () => void;
}

interface IMetaPackageStates {
    source: IDisplaySnapshotProps[];
    selectedItem: number;
    onChange: (id: number) => void;
    packages: TDisplayPackageProps[];
    description?: string;
}

interface IMetaPackageComponentProps extends IMetaPackageStates {
    code: string;
    label: string;
}

export interface IViewOrModifySnapshotProps {
    snapshotId: number;
}

const AVAILABLE_PACKAGE_TYPES = [PACKAGE_TYPE.VAR, PACKAGE_TYPE.CLIENT];

const buildItemFn = toSelectItemFn('id', 'label');

const useMetaPackageStates = (type: METAPACKAGE_TYPE, metapackages: IMetaPackageInReleaseProps): IMetaPackageStates => {
    const snapshotStore = useSnapshotStore();
    const availableList = React.useMemo<IDisplaySnapshotProps[]>(() => snapshotStore.displayDPUSnapshots[type] ?? [], [snapshotStore.displayDPUSnapshots]);
    const [selectedMetaPackage, setSelectedMetaPackage] = React.useState<number>(() => metapackages[type]?.id);

    React.useEffect(() => {
        const defaultMetapackage = metapackages[type];
        if (defaultMetapackage) {
            setSelectedMetaPackage(defaultMetapackage.id);
            return;
        }
        setSelectedMetaPackage(snapshotStore.getLatestDPUSnapshotByType(type)?.id);
    }, [metapackages, snapshotStore.displayDPUSnapshots, type]);

    const packages = React.useMemo<(TDisplayPackageProps)[]>(() => {
        return snapshotStore.getPackagesBySnapshotId(selectedMetaPackage);
    }, [selectedMetaPackage]);

    return {
        source: availableList || [],
        selectedItem: selectedMetaPackage,
        onChange: setSelectedMetaPackage,
        packages: packages,
        description: (availableList.find(snapshot => snapshot.id === selectedMetaPackage))?.description,
    };
};

export const ViewOrModifySnapshot = observer((props: IViewOrModifySnapshotProps) => {
    const { snapshotId } = props;
    const context = useSnapshotContext();

    const packagesStore = usePackagesStore();
    const snapshotStore = useSnapshotStore();

    const { canEditSnapshot } = manageSnapshotPermission();
    const { SpinnerComponent: SpiningComponent, ...spinningStates } = useSpinnerComponent();

    const snapshotDetails = React.useMemo<ISnapshotDetails>(() => {
        const snapshot = snapshotStore.getSnapshot(snapshotId);
        if (snapshot) {
            return {
                title: 'Edit software release',
                name: snapshot.label,
                description: snapshot.description,
                defaultMetapackages: snapshotStore.getMetaPackagesInRelease(snapshot.id),
                changeableTargetPackages: snapshot.packageIds,
                basedReleaseId: snapshot.basedReleaseId,
                accountUuid: snapshot.accountUuid,
                viewOnly: !canEditSnapshot(snapshot.accountUuid),
                handler: async (options: ISaveSnapshotProps): Promise<unknown> => {
                    const { apiAccountId, selectedPackages, ...releaseOptions } = options;
                    return snapshotStore.updateSnapshot(apiAccountId, snapshot.id, {
                        ...releaseOptions,
                        removePackageIds: without(snapshot.packageIds, ...selectedPackages),
                        addPackageIds: without(selectedPackages, ...snapshot.packageIds),
                    });
                },
            };
        }

        return {
            viewOnly: false,
            title: 'Add new software release',
            defaultMetapackages: snapshotStore.getMetaPackagesInRelease(undefined),
            changeableTargetPackages: [],
            handler: async (options: ISaveSnapshotProps): Promise<unknown> => {
                const { apiAccountId, selectedPackages, ...releaseOptions } = options;
                return snapshotStore.createSnapshot(apiAccountId, {
                    ...releaseOptions,
                    packageIds: selectedPackages
                });
            },
        };
    }, [snapshotId, snapshotStore.dataLoading]);

    if (snapshotDetails.viewOnly) {
        return (
            <SpiningComponent
                child={
                    <ViewSnapshotPackageComponent
                        snapshotId={snapshotId}
                        title="Software release details"
                        cancelButtonProps={{
                            type: 'primary',
                            label: 'Back to created releases',
                            onClick: context.toCreatedSnapshotTable,
                        }}
                    />
                }
            />
        );
    }

    const onSaveSnapshot = async (options: ISaveSnapshotProps): Promise<void> => {
        const packages: TDisplayPackageProps[] = [];

        // Merge all packages in base releases to check duplicated and detect dependency
        packages.push(...packagesStore.getPackagesByIds(options.selectedPackages));
        packages.push(...snapshotStore.getPackagesBySnapshotId(options.almReleaseId));
        packages.push(...snapshotStore.getPackagesBySnapshotId(options.vosReleaseId));
        packages.push(...snapshotStore.getPackagesBySnapshotId(options.scrReleaseId));
        if (showDuplicatePackagesModal({ packages })) {
            return;
        }
        if (showMissingPackageDependenciesModal({ missingPackageDependencies: packagesStore.detectDependencyPackages(packages.map(item => item.id)) })) {
            return;
        }

        spinningStates.onStart();
        try {
            await snapshotDetails.handler(options);
            context.toCreatedSnapshotTable();
        } catch (err) {
            notification.error({
                message: 'Error',
                description: getErrorMessage(err),
                placement: 'topRight'
            });
        } finally {
            spinningStates.onFinish();
        }
    };

    return (
        <SpiningComponent
            child={
                <CreateOrEditSnapshotComponent
                    {...snapshotDetails}
                    changeableTargetPackages={snapshotDetails?.changeableTargetPackages}
                    loading={snapshotStore.dataLoading || packagesStore.dataLoading}
                    onSaveSnapshot={onSaveSnapshot}
                    onCancel={(): void => confirmCancel(context.toCreatedSnapshotTable)}
                />
            }
        />
    );
});

export const CreateOrEditSnapshotComponent = observer((props: IAddPackageProps) => {
    const { snapshotStore, packagesStore } = useStores();
    const { accountStore } = useStores();
    const { currentUser } = useAuthStore();

    const [basedRelease, setBasedSnapshot] = React.useState<ISnapshotProps>(() => snapshotStore.getSnapshot(props.basedReleaseId));

    const metaPackagesOfRelease = React.useMemo(() => {
        if (basedRelease?.id === props.basedReleaseId) {
            return props.defaultMetapackages;
        }
        return snapshotStore.getMetaPackagesInRelease(basedRelease?.id);
    }, [basedRelease]);

    const organisationSource = React.useMemo<ITreeSelectDataSource>(
        () => accountStore.getSelectableTreeDataSource(Object.values(AccountType), (
            currentUser.accountType === AccountType.VAR ? [AccountType.CLIENT, AccountType.VAR] : [AccountType.CLIENT]
        )),
        [currentUser],
    );

    const [selectedClientAccount, setSelectedClientAccount] = React.useState<IAccount>(() => {
        if (props.accountUuid) {
            return accountStore.getAccountByUuid(props.accountUuid);
        }
        return currentUser.accountType === AccountType.CLIENT ? accountStore.getAccountById(currentUser.accountId) : null;
    });

    const basedSnapshotList = React.useMemo(() => {
        return snapshotStore.getSnapshotsByAccountUuid(selectedClientAccount?.uuid);
    }, [selectedClientAccount, snapshotStore.displayPublishedSnapshots]);

    React.useEffect(() => {
        const defaultSelectedBasedRelease = basedSnapshotList[0];
        if (!props.basedReleaseId) {
            setBasedSnapshot(defaultSelectedBasedRelease);
            return;
        }
        setBasedSnapshot(basedSnapshotList.find(item => item.id === props.basedReleaseId) ?? defaultSelectedBasedRelease);
    }, [basedSnapshotList]);

    const varAndClientPackages = React.useMemo<TDisplayPackageProps[]>(
        () => {
            const packages = packagesStore.displayPackages.filter(pkg => AVAILABLE_PACKAGE_TYPES.includes(pkg.type));
            if (!selectedClientAccount) {
                return packages;
            }
            return packagesStore.displayPackages
                .filter(pkg => pkg.accountUuid === selectedClientAccount.uuid || pkg.accountUuid === selectedClientAccount.parent.uuid);
        },
        [selectedClientAccount, packagesStore.displayPackages]
    );

    const changeableTargetPackages = React.useMemo(() => {
        return props.changeableTargetPackages?.filter(item => varAndClientPackages.some(pkg => pkg.id === item)).map(String) ?? [];
    }, [props.changeableTargetPackages]);

    React.useEffect(() => {
        if (!basedRelease || basedRelease.id === props.basedReleaseId) { // Priority should be given to obtaining the current selected packages.
            setTargetPackageKeys(changeableTargetPackages ?? []);
        } else {
            setTargetPackageKeys(basedRelease.packageIds.map(String));
        }
    }, [basedRelease]);

    const almBase = useMetaPackageStates(METAPACKAGE_TYPE.ALM, metaPackagesOfRelease);
    const scrBase = useMetaPackageStates(METAPACKAGE_TYPE.SCR, metaPackagesOfRelease);
    const vosBase = useMetaPackageStates(METAPACKAGE_TYPE.VOS, metaPackagesOfRelease);

    const reducer = (previousState: string[], selectedPackageIds: string[]): string[] => {
        return selectedPackageIds.filter(item => varAndClientPackages.some(pkg => pkg.id === toNumber(item)));
    };

    const [targetPackageKeys, setTargetPackageKeys] = React.useReducer(reducer, changeableTargetPackages);
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>();
    const [form] = useForm();

    const transferTableColumns: IBasicTableColumn[] = [
        {
            code: 'label',
            title: 'Package Name',
            dataIndex: 'label',
            width: '45%',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.label.localeCompare(b.label),
            onFilter: (text: string, record: TDisplayPackageProps) => hasContainsIgnoreCase(record.label, text),
            textSearchPlaceholder: 'Package label',
            iconName: 'search',
        },
        {
            code: 'version',
            title: 'Version',
            dataIndex: 'version',
            width: '20%',
        },
        {
            code: 'architecture',
            title: 'Architecture',
            dataIndex: 'architecture',
            width: '10%',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.architecture.localeCompare(b.architecture),
            filters: Object.keys(PACKAGE_ARCH).map((arch) => ({ text: PACKAGE_ARCH[arch], value: PACKAGE_ARCH[arch] })),
            onFilter: (architecture, pkg) => pkg.architecture == architecture,
        },
        {
            code: 'type',
            title: 'Type',
            dataIndex: 'type',
            width: '20%',
            filters: AVAILABLE_PACKAGE_TYPES.map((type) => ({ text: type, value: type })),
            onFilter: (type, pkg) => pkg.type == type,
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.type.localeCompare(b.type),
        },
        {
            code: 'createdAt',
            title: 'Created Date',
            dataIndex: 'createdAt',
            defaultSortOrder: 'descend',
            sorter: (a: TDisplayPackageProps, b: TDisplayPackageProps) => a.createdAtTimestamp.localeCompare(b.createdAtTimestamp),
        },
    ];

    // eslint-disable-next-line react/display-name
    const MemorizedBasedSnapshot = React.memo<Pick<ISelectItemProps, 'selectedValue' | 'dataSource' | 'onChange'>>(({
        selectedValue,
        dataSource,
        onChange
    }) => {
        const basedSnapshotCode = 'based-snapshot';
        React.useEffect(() => {
            form.setFieldsValue({ [basedSnapshotCode]: selectedValue });
        }, [selectedValue]);

        return (<SearchableSelectFormItem
            code={basedSnapshotCode}
            label="Based on"
            labelAlign="right"
            isRequired={!isNullOrEmpty(dataSource)}
            selectedValue={selectedValue}
            dataSource={dataSource}
            onChange={onChange}
        />);
    }, (prevProps, nextProps) => isEqual(prevProps.dataSource, nextProps.dataSource) && isEqual(prevProps.selectedValue, nextProps.selectedValue));

    const MetaPackageSelectComponent = ({
        code,
        label,
        source,
        packages,
        selectedItem,
        description,
        onChange
    }: IMetaPackageComponentProps): JSX.Element => {
        React.useEffect(() => {
            form.setFieldsValue({ [code]: selectedItem });
        }, [selectedItem]);

        return (
            <React.Fragment>
                <SelectFormItem
                    className={packagesCss['no-margin-form-item']}
                    key={code}
                    code={code}
                    label={label}
                    labelAlign='right'
                    isRequired={true}
                    selectedValue={selectedItem}
                    dataSource={source.map((item) => ({
                        value: item.id,
                        label: `${item.label}-${item.version}`,
                        tooltip: item.description ? item.description : `${item.label}-${item.version}`,
                        placement: 'bottomLeft',
                    }))}
                    onChange={onChange}
                    extraComponent={
                        <Typography.Text key='description' className={packagesCss['description-center']}>
                            Description: {description}
                        </Typography.Text>
                    }
                />
                <FormItem
                    label=' '
                    code={`${code}-link`}
                    labelAlign='right'
                    labelCol={{ span: 5 }}
                    colon={false}
                    child={
                        <Typography.Link
                            disabled={isNullOrEmpty(packages)}
                            onClick={(): unknown => showPackageDetailsModal(packages, description)}
                        >
                            View packages
                        </Typography.Link>
                    }
                />
            </React.Fragment>
        );
    };

    return (
        <BasicForm
            scrollToFirstError={true}
            form={form}
            onSubmit={(values): unknown => {
                if (!selectedClientAccount?.id) {
                    return notification.error({
                        message: 'Error',
                        description: 'Invalid client account',
                        placement: 'topRight',
                    });
                }
                return props.onSaveSnapshot({
                    apiAccountId: selectedClientAccount?.id,
                    label: values['snapshotName'],
                    description: values['description'] ?? undefined,
                    accountUuid: selectedClientAccount?.uuid,
                    basedReleaseId: basedRelease?.id,
                    almReleaseId: almBase.selectedItem,
                    vosReleaseId: vosBase.selectedItem,
                    scrReleaseId: scrBase.selectedItem,
                    selectedPackages: targetPackageKeys.map(Number),
                });
            }}
            items={[
                <React.Fragment key='snapshot-fragment'>
                    <Typography.Title className={packagesCss.title} level={4}>
                        {props.title}
                    </Typography.Title>
                    <TextFieldItem
                        code='snapshotName'
                        label='Name'
                        key='snapshotName'
                        labelAlign='right'
                        isRequired={true}
                        initialValue={props.name || ''}
                    />

                    <TextAreaFieldItem
                        code='description'
                        label='Description'
                        labelAlign='right'
                        textAreaProps={{
                            autoSize: { minRows: 2, maxRows: 5 },
                        }}
                        initialValue={props.description}
                    />
                    <OrganisationSelectItem
                        code='organisation'
                        label='Organisation'
                        labelAlign='right'
                        isRequired={true}
                        hidden={currentUser.accountType === AccountType.CLIENT}
                        selectProps={{
                            treeDataSource: organisationSource,
                            placeholder: 'Organisation',
                            expandAll: true,
                            onChange: (seletedId: number): void => {
                                setSelectedClientAccount(accountStore.getAccountById(seletedId));
                            },
                            value: selectedClientAccount?.id,
                        }}
                    />
                    <MemorizedBasedSnapshot
                        selectedValue={basedRelease?.id}
                        dataSource={basedSnapshotList.map(buildItemFn)}
                        onChange={(id: number): void => {
                            setBasedSnapshot(snapshotStore.getSnapshot(id));
                        }}
                    />
                    <MetaPackageSelectComponent code='alm-base' label='ALM Release' {...almBase} />
                    <MetaPackageSelectComponent code='vos-base' label='VOS Release' {...vosBase} />
                    <MetaPackageSelectComponent code='scr-base' label='SCR Release' {...scrBase} />
                    <BasicCard
                        code='packagesTransferCard'
                        title='Select packages for the software release'
                        loading={props.loading}
                        bordered={false}
                        contents={[
                            <TransferWithContentTable
                                key='packages-transfer-table'
                                code='packagesTransfer'
                                className={packagesCss.transfer}
                                listStyle={{
                                    minHeight: 400,
                                    width: 500,
                                }}
                                dataSource={varAndClientPackages}
                                targetKeys={targetPackageKeys}
                                selectedKeys={selectedKeys}
                                operations={['Add', 'Remove']}
                                titles={[
                                    <Label key='availablePackagesLabel' label='Available Packages' strong />,
                                    <Label key='previousSnapshotLabel' label='Selected Packages' strong />,
                                ]}
                                onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]): void =>
                                    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
                                }
                                onChange={(targetKeys: string[], direction: string): void => {
                                    if (direction === 'left') {
                                        confirmDialog('Are you sure you want to remove this package?', () =>
                                            setTargetPackageKeys(targetKeys),
                                        );
                                        return;
                                    }
                                    setTargetPackageKeys(targetKeys);
                                }}
                                filterOption={(inputValue: string, item: TDisplayPackageProps): boolean =>
                                    item.label.toLowerCase().includes(inputValue.toLowerCase())
                                }
                                showSearch
                                columns={transferTableColumns}
                                tableExtraOptions={{ scroll: { x: '100%' } }}
                            />,
                        ]}
                    />

                    <div className={packagesCss.row}>
                        <IconTextButton key='cancel' label='Cancel' onClick={props.onCancel} />
                        <IconTextButton key='save' label='Save' type='primary' htmlType='submit' />
                    </div>
                </React.Fragment>,
            ]}
        />
    );
});
